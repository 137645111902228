import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CHECK_EMAIL, LINK_SENT, SIGNUP } from "../../constants/api";
import LayoutEntrance from "../../LayoutEntrance";
import { toast } from "react-toastify";
import axios from "../../MyAxios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import BottomText from "../../components/BottomText";
import Constraints from "../../components/Constraints";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const Signup = () => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [disabledSU, setDisabledSU] = useState(false);
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Retrieve form data from localStorage and set state
    const storedFormData = JSON.parse(localStorage.getItem("formData")) || {};
    setValue("userName", user || storedFormData.userName);
    setValue("emailAddress", storedFormData.email || "");
    setValue("phoneNumber", storedFormData.phone || "");
    setValue("firstName", storedFormData.firstName || "");
    setValue("lastName", storedFormData.lastName || "");
    setValue("aliasOne", storedFormData.aliasOne || "");
    setValue("aliasTwo", storedFormData.aliasTwo || "");
  }, []);

  // Form submission handler
  const onSubmit = async (data) => {
    const formData = {
      userName: watch("userName"),
      phone: watch("phoneNumber"),
      firstName: watch("firstName"),
      lastName: watch("lastName"),
      email: watch("emailAddress"),
      aliasOne: watch("aliasOne"),
      aliasTwo: watch("aliasTwo"),
    };
    localStorage.setItem("formData", JSON.stringify(formData));

    if (disabledSU) {
      console.log("disable su", disabledSU);
      toast.error("Please meet all the requirements");
    } else {
      try {
        const res = await axios.post(SIGNUP, data);
        console.log(res);
        localStorage.removeItem("formData");
        navigation(LINK_SENT);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setDisabled = (e) => console.log();

  return (
    <LayoutEntrance>
      <Typography
        variant="h5"
        className={clsx(classes.textColor, "mt-3 text-center font-extrabold")}
      >
        Sign Up for for Mentee
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="flex flex-col gap-2"
      >
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Password *</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Password"
            type="password"
            {...register("password", {
              required: "Password is required",
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters",
              },
            })}
          />
          {errors.password && (
            <p className="text-xs italic text-red-500">
              {errors.password.message}
            </p>
          )}
        </div>
        <div className="input-wrapper flex flex-col">
          <label className="ml-1">
            <small>Confirm Password *</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Confirm Password"
            type="password"
            {...register("confirmPassword", {
              required: "Please confirm your password",
              validate: (value) =>
                value === watch("password") || `Passwords do not match`,
            })}
          />
          {errors.confirmPassword && (
            <p className="text-xs italic text-red-500">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>

        <Constraints
          string={watch("password")}
          setDisabledSU={setDisabledSU}
          setDisabled={setDisabled}
          forPass={true}
        />

        <div className="mt-14">
          <Button>Register Now</Button>
        </div>
      </form>
      <BottomText text1={"Go"} text2={"Back"} Back={CHECK_EMAIL} />
    </LayoutEntrance>
  );
};

export default Signup;
