import { GET_CONNECTIONS, REMOVE_CONNECTION } from "../constants/api";
import { Button } from "@mui/material";
import axios from "@MyAxios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MyAvatar from "./admin/MyAvatar";

function MyConnections() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get(GET_CONNECTIONS)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {});
  }, []);

  const removeConnection = (id) => {
    axios
      .post(REMOVE_CONNECTION, { id })
      .then((res) => {
        setUsers(users.filter((user) => user.id !== id));
        toast.success("Connection removed successfully");
      })
      .catch(() => {});
  };
  return (
    <div className=" flex flex-col p-2">
      {users?.map((user) => (
        <div
          key={user?.id}
          className=" flex justify-between gap-2 p-2 border-b-2 border-gray-100"
        >
          <MyAvatar
            name={user?.firstName + " " + user?.lastName}
            src={user?.media?.avatarUrl}
            subtext={user.userName}
          />
          <Button
            color="error"
            className="text-sm"
            sx={{
              textTransform: "none",
            }}
            onClick={() => removeConnection(user.id)}
          >
            Remove
          </Button>
        </div>
      ))}
    </div>
  );
}

export default MyConnections;
