import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import Post from "./Post";
import { useNavigate } from "react-router-dom";
import addPost from "../assets/img/addPost2.svg";
import { GET_GROUP_POSTS, GROUP_CREATE_POST } from "@constants/api";
import axios from "@MyAxios";

function GroupStreams({ groupId }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const getPosts = async () => {
    try {
      if (!groupId) return;
      const res = await axios.get(`${GET_GROUP_POSTS}/${groupId}`, {
        params: { page },
      });
      setPosts(res.data.posts);
      setTotalPages(res.data.totalPages);
      console.log(res.data);
    } catch (error) {}
  };
  useEffect(() => {
    getPosts();
  }, [groupId]);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col content-center items-center justify-center">
      <div className="w-full">
        {posts?.map((item) => (
          <div key={item.id}>
            <Post comment={true} post={item} />
          </div>
        ))}
      </div>
      <div
        onClick={() => navigate(GROUP_CREATE_POST, { state: { groupId } })}
        className="fixed bottom-10 right-5 cursor-pointer"
      >
        <Avatar className=" h-12 w-12" src={addPost} />
      </div>
    </div>
  );
}

export default GroupStreams;
