import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllToCommunities,
  addToCommunities,
  clearCommunities,
} from "../../redux/postSlice";
import { ADD_COMMUNITY_CONNECTION } from "../../constants/api";
import Layout from "../../Layout";
import { Link } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectCom() {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkedCom, setCheckedCom] = useState(false);
  const { communities } = useSelector((state) => state.post);

  const isSelected = (id) => {
    return communities.has(id);
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      dispatch(addAllToCommunities(arr));
    } else {
      dispatch(clearCommunities(arr));
    }
    setCheckedCom(e.target.checked);
  };

  const arr = [];
  return (
    <Layout>
      <Title back={ADD_COMMUNITY_CONNECTION}>Select Community</Title>
      <div className="m-5 mt-10 flex flex-col">
        <label className={clsx(classes.textGrey, "mx-3")}>
          <Checkbox
            sx={{ color: "green" }}
            checked={checkedCom}
            onChange={(e) => selectAll(e)}
          />
          Select All
        </label>
        {arr.map((item) => (
          <label key={item} className={clsx(classes.textGrey, "mx-3")}>
            <Checkbox
              sx={{ color: "green" }}
              checked={isSelected(item)}
              onChange={(e) => dispatch(addToCommunities(item))}
            />
            Community {item}
          </label>
        ))}
      </div>
      <div className=" flex items-end justify-end">
        <Link
          to={ADD_COMMUNITY_CONNECTION}
          className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
        >
          Done
        </Link>
      </div>
    </Layout>
  );
}

export default SelectCom;
