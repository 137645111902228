import AdminLogin from "@pages/admin/AdminLogin";
import Users from "@pages/admin/Users";
import Groups from "@pages/admin/Groups";
import Communities from "@pages/admin/Communities";
import Logout from "../pages/admin/Logout";
import Reports from "@pages/admin/Reports";

const addAdminRoute = (route) => {
  return "/admin" + route;
};
const adminRoutes = [
  { path: addAdminRoute("/"), element: <Users /> },
  {
    path: addAdminRoute("/login"),
    element: <AdminLogin />,
  },
  { path: addAdminRoute("/users"), element: <Users /> },
  { path: addAdminRoute("/groups"), element: <Groups /> },
  { path: addAdminRoute("/communities"), element: <Communities /> },
  { path: addAdminRoute("/reports"), element: <Reports /> },
  { path: addAdminRoute("/logout"), element: <Logout /> },
];

export default adminRoutes;
