import { Button, IconButton, TextField, Typography } from "@mui/material";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";

// max pages to show on page navigator, 2 on left and 2 on right
const maxPages = 2;
export default function PageNavigator({
  totalPages,
  setPage,
  page,
  setLimit,
  limit,
}) {
  const onPageSizeChange = (e) => {
    // page size must be between 5 and 50
    if (!e.target.value) {
      setLimit("");
      setPage(1);
    }
    if (e.target.value > 0 && e.target.value <= 50) {
      setLimit(e.target.value);
      setPage(1);
    }
  };
  return (
    <div className="mt-4 flex justify-end gap-1">
      <Typography className="mr-1 self-center">Rows per page:</Typography>
      <div className="w-20 shrink">
        <TextField
          type="number"
          value={limit}
          onChange={onPageSizeChange}
          size="small"
        />
      </div>

      <IconButton
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
        className="self-center"
      >
        <NavigateBefore />
      </IconButton>

      {Array.from({ length: totalPages }, (_, index) => index).map((_, i) => {
        if (page - maxPages <= i + 1 && i <= maxPages + page)
          return (
            <Button
              size="small"
              variant={i === page - 1 ? "contained" : "text"}
              color={i === page - 1 ? "primary" : "inherit"}
              key={i}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </Button>
          );
        else return null;
      })}
      <IconButton
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
        className="self-center"
        sx={{
          backgroundColor: page === totalPages ? "" : "primary.main",
          color: page === totalPages ? "" : "primary.contrastText",
        }}
      >
        <NavigateNext />
      </IconButton>
    </div>
  );
}
