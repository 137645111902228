import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import Layout from "../../Layout";
import Title from "../../components/Title";
import useLoggedIn from "../../hooks/useLoggedIn";
import { GET_POSTS, GET_USER_POSTS } from "../../constants/api";
import MyPost from "@components/MyPost";

const StreamManagement = () => {
  useLoggedIn();
  const [posts, setPosts] = useState([]);
  const [deletedId, setDeletedId] = useState();

  const getPosts = async () => {
    try {
      const res = await axios.get(GET_USER_POSTS);
      setPosts(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPosts();
  }, [deletedId]);

  return (
    <Layout>
      <Title back={"/"}>Stream Management</Title>
      {posts?.map((item, key) => (
        <div key={key}>
          <MyPost post={item} setDeletedId={setDeletedId} comment={false} />
        </div>
      ))}
    </Layout>
  );
};

export default StreamManagement;
