import { Avatar } from "@mui/material";

export default function MyAvatar({ name, subtext, textProps, ...props }) {
  return (
    <div className="flex items-center gap-2">
      <Avatar {...props}>{name?.length > 0 && name[0]}</Avatar>
      <div>
        <div {...textProps}>{name}</div>
        <div className={"text-sm text-gray-500"}>{subtext}</div>
      </div>
    </div>
  );
}
