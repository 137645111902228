import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function ChatBox({ sender, children, img }) {
  const classes = useStyles();
  return (
    <div>
      <div className="flex w-80 flex-col">
        <div
          className={clsx(
            `leading-1.5 my-2 flex flex-col ${
              sender
                ? "rounded-s-xl  rounded-se-xl"
                : "rounded-e-xl  rounded-ss-xl"
            } p-4`,
            sender ? classes.bgGreen : classes.bgGrey
          )}
        >
          {img && (
            <img
              className=" mb-3 h-40 bg-white object-contain"
              src={img}
              alt="Media"
            />
          )}
          <p className="text-sm font-normal">{children}</p>
        </div>
      </div>
    </div>
  );
}

export default ChatBox;
