import axios from "../../MyAxios";
import { GET_TOKEN } from "../../constants/api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AuthCallback = () => {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState();
  const { token } = useParams();
  const getToken = async () => {
    const res = await axios.get(`${GET_TOKEN}/${token}`);
    setUserToken(res.data);
  };

  useEffect(() => {
    console.log(token);
    if (token) {
      getToken();
      localStorage.setItem("token", userToken);

      navigate("/", { replace: true });
    }
  }, [token]);
};

export default AuthCallback;
