import React, { useState } from "react";
import { Checkbox, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  CREATE_POST,
  ROOT,
  SELECT_COMMUNITY,
  SELECT_CONNECTION,
  SELECT_GROUP,
} from "../../constants/api";
import Layout from "../../Layout";
import { resetPost } from "../../redux/postSlice";
import useLoggedIn from "../../hooks/useLoggedIn";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function AddComCon() {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { connections, selectedGroups } = useSelector((state) => state.post);

  const post = useSelector((state) => state.post);
  const navigation = useNavigate();

  const handlePost = async () => {
    try {
      const formData = new FormData();
      formData.append("title", localStorage.postTitle || "");
      formData.append("description", localStorage.postDescription || "");
      formData.append("mediaId", localStorage.media || "");
      formData.append("connections", Array.from(post.connections));
      formData.append("communities", Array.from(post.communities));
      formData.append("groups", Array.from(post.selectedGroups));
      console.log(post);
      await axios.post(CREATE_POST, formData).then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Post successfully");
          dispatch(resetPost());
          navigation(ROOT);
        }
      });
    } catch (error) {}
  };

  return (
    <Layout>
      <Title back={CREATE_POST}>Post Stream</Title>
      <div className="m-5 mt-10">
        <label className={clsx(classes.textGrey, "mx-3")}>
          <Checkbox
            sx={{ color: "green" }}
            checked={selectedGroups?.length > 0}
          />
          Post to a Group
        </label>
        <Link
          to={SELECT_GROUP}
          className={clsx(
            classes.bgGrey,
            "mx-5 flex cursor-pointer justify-between rounded-lg p-4"
          )}
        >
          <Typography className={clsx(classes.textGrey)}>
            Select Group
          </Typography>
          <ArrowForwardIcon />
        </Link>
      </div>
      <div className="m-5 mt-10">
        <label className={clsx(classes.textGrey, "mx-3")}>
          <Checkbox checked={connections.length > 0} sx={{ color: "green" }} />
          Share With Connections
        </label>
        <Link
          to={SELECT_CONNECTION}
          className={clsx(
            classes.bgGrey,
            "mx-5 flex cursor-pointer justify-between rounded-lg p-4"
          )}
        >
          <Typography className={clsx(classes.textGrey)}>
            Select Connections
          </Typography>
          <ArrowForwardIcon />
        </Link>
      </div>
      <div className=" flex items-end justify-end">
        <button
          onClick={handlePost}
          className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
        >
          Post
        </button>
      </div>
    </Layout>
  );
}

export default AddComCon;
