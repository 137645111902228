import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import axios from "../../MyAxios";
import { setCommunity } from "../../redux/groupSlice";
import { CREATE_GROUP, GET_COMMUNITIES, ROOT } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function CreateGroup(data) {
  useLoggedIn();
  const classes = useStyles();
  const { userRecord } = useSelector((state) => state.user);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [commName, setCommName] = React.useState();
  const [communities, setCommunities] = useState();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCommName(value);
    dispatch(setCommunity(value));
    localStorage.setItem("community", value);
  };

  const handleClick = () => {
    if (!commName) {
      toast.error("Please Select a Community");
    } else {
      navigation(CREATE_GROUP);
    }
  };

  useEffect(() => {
    const getComm = async () => {
      try {
        await axios.get(GET_COMMUNITIES).then((res) => {
          setCommunities(res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    if (userRecord.role !== "admin") {
      navigation(CREATE_GROUP, { replace: true });
    } else getComm();
  }, []);

  return (
    <Layout>
      <Title back={ROOT}>Create Group</Title>
      <div className="mx-10 my-4 mt-10">
        <Typography className={clsx(classes.textGrey, " text-md")}>
          Select Community
        </Typography>
        <Typography className={clsx(classes.textGrey, " text-sm")}>
          Your group needs a home! Choose a community where it belongs. Simply
          select a community from the dropdown to proceed.
        </Typography>
        <FormControl
          className={clsx(classes.bgGrey)}
          sx={{ marginTop: 2, width: 320 }}
        >
          <InputLabel id="demo-multiple-name-label">
            Select Community
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={commName}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {communities?.map((name) => (
              <MenuItem key={name?.id} value={name?.id}>
                {name?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className=" flex items-end justify-end">
        <button
          onClick={handleClick}
          className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
        >
          Next
        </button>
      </div>
    </Layout>
  );
}

export default CreateGroup;
