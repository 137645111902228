import { Modal, Typography, Button } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";

export default function ConfirmationModal({
  open,
  setOpen,
  buttons,
  children,
}) {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md">
        <Typography className="mb-8" variant="body1" component="h2">
          {children}
        </Typography>
        <div className="flex w-full justify-end gap-2 mt-6">
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          {buttons.map((button, index) => (
            <div className="text-white" key={index}>
              <Button {...button}></Button>
            </div>
          ))}
        </div>
      </ModalBox>
    </Modal>
  );
}
