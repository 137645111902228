import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "../MyAxios";
import { toast } from "react-toastify";
import { DELETE_POST, POST, REPORT_FORM } from "../constants/api";
import PostButton from "./PostButton";
import { useTheme } from "@emotion/react";
import share from "../assets/img/share.svg";
import link from "../assets/img/link.svg";
import commentimg from "../assets/img/comment.svg";
import report from "../assets/img/report.svg";
import addConnection from "../assets/img/addConnection.svg";
import saved from "../assets/img/saved.svg";
import save from "../assets/img/save.svg";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { calTime } from "../helpers";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Post({ post, comment, savedItems }) {
  const [expanded, setExpanded] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const theme = useTheme();
  const [reload, setReload] = React.useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const viewPost = (id) => {
    if (id && comment) {
      try {
        navigate(POST, { state: { id: id } });
      } catch (error) {
        toast.error(error);
      }
    }
  };

  React.useEffect(() => {
    if (savedItems?.length > 0) {
      let flag = false;
      savedItems.forEach((item) => {
        if (post?.id === item?.postSaved?.id) {
          flag = true;
        }
      });
      setIsSaved(flag);
    } else {
      setIsSaved(post?.isSaved);
    }
  }, [savedItems, post]);

  console.log("Saved", isSaved);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onDelete = async (post) => {
    try {
      await axios.post(DELETE_POST, { post }).then((res) => {
        toast.success("Post Deleted Successfully");
      });
    } catch (error) {}
  };
  const actions = [
    {
      id: 1,
      src: share,
      shown: true,
    },
    {
      id: 2,
      src: link,
      shown: true,
    },
    {
      id: 3,
      src: commentimg,
      onClick: () => viewPost(post?.id),
      shown: comment,
    },
    {
      id: 4,
      src: report,
      onClick: () => {
        navigate(REPORT_FORM, { state: { id: post?.id, report: "post" } });
      },
      shown: true,
    },
  ];
  const onSavePost = async (id) => {
    try {
      console.log("saving post with id", id);
      await axios.post("/save/post", { id });
      toast.success("Post saved successfully");
      setIsSaved(true);
      setReload(reload + 1);
    } catch (error) {}
  };

  const onUnSavePost = async (id) => {
    try {
      console.log("unsaving post with id", id);
      await axios.post("/unsave/post", { id });
      toast.success("Post unsaved successfully");
      setIsSaved(false);
      setReload(reload + 1);
    } catch (error) {}
  };
  return (
    <div className="">
      <Card
        onClick={() => viewPost(post.id)}
        className={clsx(
          " z-0 mx-auto mb-5 shadow-none",
          comment && "cursor-pointer"
        )}
      >
        <CardHeader
          avatar={<Avatar src={post?.user?.media?.avatarUrl} />}
          title={
            <Typography className=" text-sm">
              {`${post?.user?.firstName} ${post?.user?.lastName}`}
              <small
                style={{ color: theme.palette.text.dark }}
                className="ml-2"
              >
                {calTime(post?.createdAt)}
              </small>
            </Typography>
          }
          subheader={
            <div className="flex">
              <Typography className=" text-xs">
                {post?.user?.userName}
              </Typography>
            </div>
          }
        />
        <Typography
          variant="body2"
          className="mb-2 ml-4"
          color="text.secondary"
        >
          {post?.title}
        </Typography>
        {post?.media?.avatarUrl && (
          <CardMedia
            component="img"
            className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
            image={post?.media?.avatarUrl}
            alt="Stream Media"
          />
        )}
        <CardActions
          onClick={(e) => e.stopPropagation()}
          disableSpacing
          className="mt-3 flex justify-between md:justify-center"
        >
          <div className="flex">
            {actions.map((action) => (
              <div key={action.id}>
                {action.shown && (
                  <img
                    src={action.src}
                    key={action.id}
                    className="mx-3 cursor-pointer"
                    onClick={action.onClick}
                    alt="Action"
                  />
                )}
              </div>
            ))}
          </div>
          {!isSaved && (
            <img
              className="mr-4 cursor-pointer"
              src={save}
              onClick={() => onSavePost(post.id)}
              alt="Save"
            />
          )}
          {isSaved && (
            <img
              className="mr-4 cursor-pointer"
              src={saved}
              onClick={() => onUnSavePost(post.id)}
              alt="Saved"
            />
          )}
        </CardActions>
        {comment && (
          <button onClick={() => viewPost(post.id)}>
            <Typography variant="body2" color="text.secondary" className="ml-4">
              View {post?.comments} comments
            </Typography>
          </button>
        )}
      </Card>
    </div>
  );
}
