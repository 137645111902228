import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Checkbox, IconButton, Typography } from "@mui/material";
import clsx from "clsx";
import { useState, useEffect } from "react";

function Table({
  data,
  fields,
  checked,
  setChecked,
  setSortColumns,
  sortColumns,
}) {
  const [isAllChecked, setIsAllChecked] = useState(false);

  const onCheckedClickHandler = (id) => {
    setChecked({ ...checked, [id]: !checked[id] });
  };

  // if select all clicked, change vlaue of all checkboxes
  useEffect(() => {
    const obj = {};
    data.forEach((item) => {
      obj[item.id] = isAllChecked;
    });
    setChecked(obj);
  }, [isAllChecked, setChecked, data]);

  return (
    <div
      style={{
        maxHeight: "calc(100vh - 250px)",
        maxWidth: "calc(100vw - 200px)",
      }}
      className="relative mt-4 overflow-auto overflow-x-auto"
    >
      <table className="w-full text-left text-sm text-gray-500">
        <thead className="border-b-2 border-gray-200 text-xs uppercase text-gray-700">
          <tr>
            <th>
              <div className="flex items-center gap-1">
                <Checkbox
                  checked={isAllChecked}
                  onClick={() => setIsAllChecked(!isAllChecked)}
                />
                <Typography>
                  {Object.values(checked).filter((value) => value).length}
                </Typography>
              </div>
            </th>
            {fields.map((field, id) => (
              <th key={id} scope="col" className="px-6 py-3">
                <div className="flex items-center gap-1">
                  <div>{field.columnName}</div>
                  {/* button for ASC SORT */}
                  {((field.field && !sortColumns[field.field]) ||
                    sortColumns[field.field] === "ASC") && (
                    <IconButton
                      onClick={() =>
                        setSortColumns({
                          [field.field]: "DESC",
                        })
                      }
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  )}
                  {/* button for DESC SORT */}
                  {field.field && sortColumns[field.field] === "DESC" && (
                    <IconButton
                      onClick={() =>
                        setSortColumns({
                          [field.field]: "ASC",
                        })
                      }
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, id) => (
            <tr
              key={id}
              className={clsx(
                "border-b bg-white",
                id === data.length - 1 && "border-b-2 border-gray-200"
              )}
            >
              <td>
                <Checkbox
                  checked={checked[item.id] || false}
                  onClick={() => onCheckedClickHandler(item.id)}
                />
              </td>
              {fields.map((field, id1) => (
                <td key={id1} className="max-w-80 truncate px-6 py-4">
                  {field.render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
