import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import filters from "@images/filters.svg";
import streams from "@images/streams.svg";
import groupsIcon from "@images/groups.svg";
import people from "@images/people.svg";
import clsx from "clsx";
import addConnection from "@images/addConnection.svg";
import message from "@images/message.svg";
import axios from "@MyAxios";
import {
  BLOCK_USER,
  GET_ALL_USERS,
  GET_POSTS,
  GET_USER_CHAT,
  REQUEST_CONNECTION,
  SEARCH_GROUPS,
} from "@constants/api";
import send from "../../assets/img/send.svg";
import Post from "@components/Post";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GroupModal from "@components/GroupModal";
import LayoutEntrance from "../../LayoutEntrance";
import SearchIcon from "@mui/icons-material/Search";
import back from "@images/back.svg";
import { toast } from "react-toastify";
import MyAvatar from "@components/admin/MyAvatar";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Search = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState("Streams");
  const [search, setSearch] = React.useState(null);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [groups, setGroups] = useState([]);
  const [openUserOptions, setOpenUserOptions] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [totalPages, setTotalPages] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { userRecord } = useSelector((state) => state.user);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };

  const handleCLose = (name) => {
    setPage(1);
    setSelected(name);
    setAnchorEl(false);
  };

  const GetPosts = async () => {
    try {
      await axios.get(`${GET_POSTS}/${page}/${search}`).then((res) => {
        console.log(res.data);
        if (page === 1) setPosts(res.data.posts);
        else setPosts([...posts, ...res.data.posts]);
        setTotalPages(res.data.totalPages);
      });
    } catch (error) {}
  };
  const getUsers = async () => {
    try {
      const res = await axios.get(`${GET_ALL_USERS}/${page}/${search}`);
      if (page === 1) setUsers(res.data.users);
      else setUsers([...res.data.users, ...users]);
      setTotalPages(res.data.totalPages);
    } catch (error) {}
  };

  const getGroups = async () => {
    try {
      const res = await axios.get(`${SEARCH_GROUPS}/${page}/${search}`);
      if (page === 1) setGroups(res.data.groups);
      else setGroups([...res.data.groups, ...groups]);
      setTotalPages(res.data.totalPages);
    } catch (error) {}
  };
  useEffect(() => {
    onSearch();
  }, [page, selected]);
  const userOptions = [
    {
      id: 1,
      name: "Connect",
      img: addConnection,
      action: () => {
        axios
          .post(REQUEST_CONNECTION, { forUser: selectedUser?.id })
          .then((res) => {
            toast.success("Connection request sent successfully");
            setOpenUserOptions(false);
          })
          .catch(() => {});
      },
    },
    {
      id: 2,
      name: "Message",
      img: message,
      action: () => {
        console.log(selectedUser);
        navigate(`${GET_USER_CHAT}`, {
          state: { receiverId: selectedUser?.id },
        });
      },
    },
    {
      id: 3,
      name: "Block",
      img: send,
      action: () => {
        console.log(selectedUser);
        axios
          .post(BLOCK_USER, { id: selectedUser?.id })
          .then((res) => {
            toast.success("User blocked successfully");
            setOpenUserOptions(false);
          })
          .catch(() => {});
      },
    },
  ];

  const groupOptions = [
    // {
    //   id: 1,
    //   name: "Join",
    //   img: addConnection,
    //   action: () => {
    //     console.log("join group", selectedGroup);
    //   },
    // },
  ];
  const onSearch = () => {
    console.log(selected);
    if (selected === "Streams") {
      GetPosts();
    } else if (selected === "People") {
      getUsers();
    } else if (selected === "Groups") {
      getGroups();
    }
  };
  const options = [
    {
      id: 1,
      name: "Streams",
      img: streams,
    },
    {
      id: 2,
      name: "Groups",
      img: groupsIcon,
    },
    {
      id: 3,
      name: "People",
      img: people,
    },
    // {
    //   id: 4,
    //   name: "Resource",
    //   img: resource,
    // },
  ];
  return (
    <LayoutEntrance>
      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => navigate(-1)}
          alt="Back"
          className="w-6 h-6 mr-2"
          src={back}
        />
        <TextField
          type="text"
          size="small"
          id="outlined-basic"
          fullWidth
          value={search}
          placeholder="Search"
          InputProps={{
            sx: {
              borderRadius: 10,
            },
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={() => onSearch(search)} className="ml-2" type="submit">
          <SearchIcon />
        </button>
        <div>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
            endIcon={<Avatar src={filters} />}
          ></Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option) => (
              <MenuItem
                onClick={() => handleCLose(option.name)}
                key={option.id}
                disableRipple
              >
                <img className="mr-3" src={option.img} alt={option.name} />
                {option.name}
              </MenuItem>
            ))}
          </StyledMenu>
        </div>
      </Box>
      {selected === "Groups" && (
        <div>
          {groups.length === 0 && (
            <Typography className="m-3 text-center">
              {" "}
              No groups found
            </Typography>
          )}
          {groups.map((group, key) => (
            <div className="flex justify-between border-b-2 border-gray-100 py-4">
              <MyAvatar
                key={key}
                name={group.name}
                src={group?.icon?.avatarUrl}
              />
              <MoreHorizIcon
                onClick={() => {
                  setSelectedGroup(group);
                }}
                className=" cursor-pointer"
                fontSize="large"
              />
              <GroupModal
                open={selectedGroup}
                setOpen={(open) => {
                  if (!open) setSelectedGroup(null);
                }}
                options={groupOptions}
              />
            </div>
          ))}
          {page < totalPages && (
            <Typography
              onClick={() => setPage(page + 1)}
              className=" text-center"
            >
              Show More
            </Typography>
          )}
        </div>
      )}

      {selected === "Streams" && (
        <div>
          {posts.length === 0 && (
            <Typography className="m-3 text-center"> No posts found</Typography>
          )}
          {posts?.map((item) => (
            <div
              key={item?.id}
              // style={{ borderColor: theme.palette.primary.main }}
              className=" border-b-2"
            >
              <Post post={item} comment={true} />
            </div>
          ))}
          {page < totalPages && (
            <Typography
              onClick={() => setPage(page + 1)}
              className=" text-center"
            >
              Show More
            </Typography>
          )}
        </div>
      )}
      {selected === "People" && (
        <div>
          {users.length === 0 && (
            <Typography className="m-3 text-center"> No users found</Typography>
          )}
          {users?.map((member) => (
            <div
              key={member.id}
              className="flex mt-2 items-center mr-5 justify-between"
            >
              <div className="flex">
                <Avatar
                  src={member?.media?.avatarUrl}
                  className="z-0 w-14 h-14 mr-3"
                />
                <div className="flex flex-col justify-center">
                  <Typography className={clsx("font-bold")}>
                    {member?.firstName} {member?.lastName}
                  </Typography>
                  <Typography className={clsx("")}>
                    {member?.userName}
                  </Typography>
                </div>
              </div>
              <MoreHorizIcon
                onClick={() => {
                  setSelectedUser(member);
                  setOpenUserOptions(true);
                }}
                className=" cursor-pointer"
                fontSize="large"
              />
              <GroupModal
                open={openUserOptions}
                setOpen={setOpenUserOptions}
                options={userOptions}
              />
              {page < totalPages && (
                <Typography
                  onClick={() => setPage(page + 1)}
                  className=" text-center"
                >
                  Show More
                </Typography>
              )}
            </div>
          ))}
        </div>
      )}
    </LayoutEntrance>
  );
};

export default Search;
