import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "../MyAxios";
import { toast } from "react-toastify";
import {
  DELETE_MESSAGE,
  REPORT_FORM,
  SAVE_MESSAGE,
  UNSAVE_MESSAGE,
} from "../constants/api";
import deleteImg from "../assets/img/delete.svg";
import copy from "../assets/img/copy.svg";
import saveGreen from "../assets/img/saveGreen.svg";
import reportGreen from "../assets/img/reportGreen.svg";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useLocation, useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ChatOptions({ msg }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [saved, setSaved] = React.useState(msg.isSaved);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const { userRecord } = useSelector((state) => state.user);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = async () => {
    try {
      const res = await axios.post(DELETE_MESSAGE, { id: msg.id });
      toast.success(res.data.message);
    } catch (error) {}
    setAnchorEl(null);
  };

  const handleCloseSave = async () => {
    try {
      const res = await axios.post(SAVE_MESSAGE, { id: msg.id });
      toast.success(res.data.message);
      setSaved(true);
    } catch (error) {}
    setAnchorEl(null);
  };

  const handleCloseUnSave = async () => {
    try {
      const res = await axios.post(UNSAVE_MESSAGE, { id: msg.id });
      toast.success(res.data.message);
      setSaved(false);
    } catch (error) {}
    setAnchorEl(null);
  };

  const handleCloseReport = () => {
    navigate(REPORT_FORM, { state: { id: msg?.id, report: "message" } });
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={
          open ? (
            <KeyboardArrowUpIcon fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" />
          )
        }
      ></Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {msg?.user?.id === userRecord?.id && (
          <MenuItem onClick={() => handleCloseDelete()} disableRipple>
            <img className="mr-3" src={deleteImg} alt="Delete" />
            Delete
          </MenuItem>
        )}
        <CopyToClipboard text={msg?.message}>
          <MenuItem onClick={() => handleClose()} disableRipple>
            <img className="mr-3" src={copy} alt="Copy" />
            Copy
          </MenuItem>
        </CopyToClipboard>
        {saved ? (
          <MenuItem onClick={() => handleCloseUnSave()} disableRipple>
            <img className="mr-3" src={saveGreen} alt="Save" />
            Unsave
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleCloseSave()} disableRipple>
            <img className="mr-3" src={saveGreen} alt="Save" />
            Save
          </MenuItem>
        )}
        {msg?.user?.id !== userRecord?.id && (
          <MenuItem onClick={handleCloseReport} disableRipple>
            <img className="mr-3" src={reportGreen} alt="Report" />
            Report
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
