import { LOGIN } from "../constants/api";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
}));

function BottomText({ text1, text2, Back }) {
  const classes = useStyles();
  return (
    <div className="absolute bottom-3 left-1/2">
      <Typography className={clsx("relative -left-1/2 z-50 text-center")}>
        {text1}
        <Link to={Back} className={clsx(classes.textColor)}>
          {" "}
          {text2}
        </Link>
      </Typography>
    </div>
  );
}

export default BottomText;
