import { createSlice } from '@reduxjs/toolkit'

export const GroupSlice = createSlice({
  name: 'community',
  initialState: {
    community: '',
  },
  reducers: {
    setCommunity: (state, action) => {
      state.community = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCommunity } = GroupSlice.actions

export default GroupSlice.reducer
