import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "../../MyAxios";
import { LOGIN, RESET_PASSWORD, USERNAME } from "../../constants/api";
import Button from "../../components/Button";
import LayoutEntrance from "../../LayoutEntrance";
import wave from "../../assets/img/wave.svg";
import google from "../../assets/img/google.svg";
import facebook from "../../assets/img/facebook.svg";
import mentee from "../../assets/img/mentee.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoggedIn } from "../../redux/userSlice";
import { setUserRecord } from "@redux/userSlice";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  borderTop: {
    borderTop: `2px solid #000`,
    width: "30%",
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Form submission handler
  const onSubmit = async (data) => {
    try {
      const res = await axios.post(LOGIN, data);
      console.log(res);
      localStorage.setItem("token", res.data.token);
      dispatch(setIsLoggedIn(true));
      dispatch(setUserRecord(data.user));

      navigation("/");
    } catch (error) {
      console.log(error);
      setError(error.response.data.message);
    }
    console.log("redirecting");
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);

  return (
    <div>
      <LayoutEntrance>
        <div className="mt-20">
          <img src={mentee} alt="mentee" className="mx-auto mb-3" />
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "text-center")}
          >
            Connect, Learn, Thrive
          </Typography>
          <Typography
            variant="h6"
            className={clsx(classes.textColor, "mb-3 text-center")}
          >
            with Mentee
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Username or Email"
              type="text"
              {...register("userName", {
                required: "Username is required",
                minLength: {
                  value: 3,
                  message: "Username must be at least 3 characters",
                },
              })}
            />
            {errors.userName && (
              <p className="text-xs italic text-red-500">
                {errors.userName.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <input
              placeholder="Password"
              className={clsx(classes.border, "rounded-md p-3")}
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              })}
            />
            {errors.password && (
              <p className="text-xs italic text-red-500">
                {errors.password.message}
              </p>
            )}
          </div>

          <div className="mt-3">
            <Button>Login</Button>
          </div>
        </form>
        <div className="my-4 mt-7 flex items-center justify-center">
          <div className={clsx(classes.borderTop, "mr-5")}></div>
          <div>
            <Typography> Or Login with </Typography>
          </div>
          <div className={clsx(classes.borderTop, "ml-5")}></div>
        </div>
        <div className="flex items-center justify-evenly">
          <a
            href={`${
              process.env.REACT_APP_URL ?? "http://localhost:1337"
            }/auth/google`}
            className="cursor-pointer"
          >
            <img src={google} alt="Google" />
          </a>
          <a
            href={`${
              process.env.REACT_APP_URL ?? "http://localhost:1337"
            }/auth/facebook`}
          >
            <img src={facebook} alt="Facebook" />
          </a>
        </div>
        <div className="input-wrapper z-50 mt-12">
          <Typography className={clsx("text-center text-lg")}>
            <Link to={USERNAME} className={clsx(classes.textColor)}>
              {" "}
              Create New Account
            </Link>
          </Typography>
        </div>
        <div className="input-wrapper z-50 mb-5">
          <Link to={RESET_PASSWORD}>
            <Typography className="cursor-pointer text-center text-sm underline">
              Forgot Password?
            </Typography>
          </Link>
        </div>
      </LayoutEntrance>
      {height > 750 && (
        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      )}
    </div>
  );
};

export default Login;
