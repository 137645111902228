import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllToCommunities,
  addAllToGroups,
  addToCommunities,
  addToGroups,
  clearCommunities,
  clearGroups,
} from "../../redux/postSlice";
import {
  ADD_COMMUNITY_CONNECTION,
  GET_GROUPS_BY_MEMBER,
} from "../../constants/api";
import Layout from "../../Layout";
import { Link } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import axios from "@MyAxios";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectGroup() {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkedGroup, setCheckedGroup] = useState(false);
  const { selectedGroups } = useSelector((state) => state.post);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    axios
      .get(GET_GROUPS_BY_MEMBER)
      .then((res) => {
        setGroups(res.data);
      })
      .catch(() => {});
  }, []);

  const isSelected = (id) => {
    return selectedGroups.includes(id);
  };

  const selectAll = (e) => {
    if (e.target.checked) {
      dispatch(addAllToGroups(groups.map((g) => g.id)));
    } else {
      dispatch(clearGroups());
    }
    setCheckedGroup(e.target.checked);
  };

  return (
    <Layout>
      <Title back={ADD_COMMUNITY_CONNECTION}>Select Group</Title>
      <div className="m-5 mt-10 flex flex-col">
        <label className={clsx(classes.textGrey, "mx-3")}>
          <Checkbox
            sx={{ color: "green" }}
            checked={checkedGroup}
            onChange={(e) => selectAll(e)}
          />
          Select All
        </label>
        {groups.map((group, key) => (
          <div className="flex">
            <label key={key} className={clsx(classes.textGrey, "mx-3")}>
              <Checkbox
                sx={{ color: "green" }}
                checked={isSelected(group.id)}
                onChange={(e) => dispatch(addToGroups(group.id))}
              />
            </label>
            <MyAvatar src={group.icon?.avatarUrl} name={group.name} />
          </div>
        ))}
      </div>
      <div className=" flex items-end justify-end">
        <Link
          to={ADD_COMMUNITY_CONNECTION}
          className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
        >
          Done
        </Link>
      </div>
    </Layout>
  );
}

export default SelectGroup;
