import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import axios from "../../MyAxios";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EDIT_GROUP,
  GET_GROUP,
  GROUP_ADD_MEMBERS,
  GROUP_ADD_RESOURCES,
  ROOT,
} from "../../constants/api";
import Layout from "../../Layout";
import back from "../../assets/img/back.svg";
import GroupStreams from "../../components/GroupStreams";
import GroupDescription from "../../components/GroupDescription";
import { useLocation, useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import addConnection from "@images/addMembers.svg";
import addResources from "@images/addResources.svg";
import editGroup from "@images/editGroup.svg";
import remove from "@images/delete.svg";
import GroupModal from "@components/GroupModal";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#b1b1b1",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  active: {
    color: "#626262",
    borderBottomWidth: "4px",
    borderColor: theme.palette.primary.main,
  },
}));

function GroupInfo(res) {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [active, setActive] = useState(1);
  const [group, setGroup] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { userRecord } = useSelector((state) => state.user);

  const options = [
    {
      id: 1,
      name: "Add Members",
      img: addConnection,
      action: () => {
        navigate(GROUP_ADD_MEMBERS, { state: { groupId: group?.id } });
      },
    },
    {
      id: 2,
      name: "Add Resources",
      img: addResources,
      action: () => {
        navigate(GROUP_ADD_RESOURCES, { state: { groupId: group?.id } });
      },
    },
    {
      id: 3,
      name: "Edit Group",
      img: editGroup,
      action: () => {
        navigate(EDIT_GROUP, { state: { group: group } });
      },
    },
    // {
    //   id: 4,
    //   name: "Delete",
    //   img: remove,
    // },
  ];

  useEffect(() => {
    const GetGroup = async () => {
      try {
        await axios.get(`${GET_GROUP}/${id}`).then((res) => {
          console.log(res.data);
          setGroup(res.data);
        });
      } catch (error) {
        console.log(error);
      }
    };
    GetGroup();
  }, []);

  return (
    <Layout>
      <div className=" z-50 w-full bg-white">
        <div
          className={clsx(
            classes.colorGreen,
            "shadow-black-100 flex justify-between py-2 shadow-md"
          )}
        >
          <div className=" ml-5 flex  items-center">
            <img
              onClick={() => navigation(ROOT)}
              alt="Back"
              className="h-5 w-5"
              src={back}
            />
            <Avatar
              className=" ml-3 h-14 w-14 rounded-full"
              src={group?.icon?.avatarUrl}
            />
            <div className=" ml-4">
              <Typography className={clsx(" leading-3")}>
                {group?.name}
              </Typography>
              <Typography className={clsx(classes.textGrey)}>
                <small>Members {group?.members?.length}</small>
              </Typography>
            </div>
          </div>
          {group?.admin?.id === userRecord?.id && (
            <div>
              <MoreVertIcon
                sx={{ alignSelf: "center", height: "55px" }}
                fontSize="large"
                className=" cursor-pointer"
                onClick={() => setOpen(true)}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row items-center cursor-pointer justify-center p-2">
          <Typography
            onClick={() => setActive(1)}
            className={clsx(
              "w-1/2 p-2 text-center",
              active === 1 ? classes.active : classes.textGrey
            )}
          >
            Streams
          </Typography>
          <Typography
            onClick={() => setActive(2)}
            className={clsx(
              "w-1/2 p-2 text-center",
              active === 2 ? classes.active : classes.textGrey
            )}
          >
            Group Info
          </Typography>
        </div>
      </div>

      {active === 1 ? (
        <div>
          <GroupStreams groupId={group?.id} />
        </div>
      ) : (
        <div>
          <GroupDescription group={group} />
        </div>
      )}
      <GroupModal open={open} setOpen={setOpen} options={options} />
    </Layout>
  );
}

export default GroupInfo;
