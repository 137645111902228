import { GET_GROUPS, GROUP_INFO } from "../constants/api";
import { Avatar, Typography } from "@mui/material";
import axios from "../MyAxios";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function MyGroups() {
  const [groups, setGroups] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    const getGroups = async () => {
      try {
        const res = await axios.get(GET_GROUPS);
        setGroups(res.data);
      } catch (error) {
        toast.error(error.response.data);
      }
    };
    getGroups();
  }, []);

  const groupInfo = (id) => {
    navigation(GROUP_INFO, { state: { id } }, { replace: true });
  };

  return (
    <div className=" flex flex-col items-center p-2">
      {groups?.map((group) => (
        <div
          key={group?.id}
          onClick={() => groupInfo(group?.id)}
          className=" flex w-full flex-row cursor-pointer items-center border-b-2 p-2"
        >
          <div>
            <Avatar
              className=" h-10 w-10 rounded-full"
              src={group?.icon?.avatarUrl}
            />
          </div>
          <div className="flex w-full justify-between p-2 pl-4">
            <div>
              <Typography
                color={"text.dark"}
                className={clsx("text-xs font-bold")}
              >
                {group.name}
              </Typography>
              <Typography className={clsx(" leading-none")}>
                <small>{group.name}</small>
              </Typography>
            </div>
            <Typography
              onClick={(e) => {
                console.log("delete");
                e.stopPropagation();
              }}
              className={clsx(" leading-none text-red-600")}
            >
              <small>Delete</small>
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MyGroups;
