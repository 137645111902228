import React from "react";
import { useEffect, useState } from "react";
import axios from "../../MyAxios";
import Post from "../../components/Post";
import { GET_SAVED_POSTS } from "@constants/api";

const SavedPosts = () => {
  const [savedItems, setSavedItems] = useState([]);
  useEffect(() => {
    const getSavedItems = async () => {
      try {
        await axios.get(GET_SAVED_POSTS).then((res) => {
          setSavedItems(res.data);
          console.log(res.data);
        });
      } catch (error) {}
    };
    getSavedItems();
  }, []);
  return (
    <div>
      {savedItems.map((item, key) => {
        if (item.postSaved)
          return (
            <div key={key}>
              <Post post={item.postSaved} comment={false} />
            </div>
          );
        else return null;
      })}
    </div>
  );
};

export default SavedPosts;
