import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setPasswordResetUser } from "../../redux/userSlice";
import { ENTER_OTP, LOGIN, RESET_PASSWORD } from "../../constants/api";
import axios from "../../MyAxios";
import wave from "../../assets/img/wave.svg";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const ResetPassword = (res) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Form submission handler
  const onSubmit = async (data) => {
    // Do something with the form data
    console.log(data);
    dispatch(setPasswordResetUser(data.emailAddress));
    try {
      const res = await axios.post(RESET_PASSWORD, data);
      navigate(ENTER_OTP);
    } catch (error) {}
  };

  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h4"
            className={clsx(
              classes.textColor,
              "mt-3 text-center font-extrabold"
            )}
          >
            Password
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.textColor, "text-center font-extrabold")}
          >
            Slip Up?
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.textColor, " text-center font-extrabold")}
          >
            We'll Sort It Out!
          </Typography>
        </div>
        <div className="mt-5">
          <Typography variant="p" className={clsx("block text-center")}>
            <small>
              Please enter your username or email to receive the
              <span className={classes.textColor}> verification code.</span>
            </small>
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Email or Username"
              type="text"
              {...register("emailAddress", {
                required: "Entry is required",
              })}
            />
            {errors.emailAddress && (
              <p className="text-xs italic text-red-500">
                {errors.emailAddress.message}
              </p>
            )}
          </div>

          {res.message && (
            <p className="text-xs italic text-red-500">{res.message}</p>
          )}

          <div className="input-wrapper">
            <button
              type="submit"
              className={clsx(classes.button, "mt-3 w-full rounded-3xl p-2")}
            >
              Next
            </button>
          </div>
        </form>
        <div className="input-wrapper z-50 mt-3">
          <Typography className={clsx("text-center")}>
            Back to
            <Link to={LOGIN} className={clsx(classes.textColor)}>
              {" "}
              Login
            </Link>
          </Typography>
        </div>
        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
