import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    user: "",
    passwordResetUser: "",
    passwordResetOtp: "",
    userRecord: {},
    isLoggedIn: localStorage.token ? true : false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPasswordResetUser: (state, action) => {
      state.passwordResetUser = action.payload;
    },
    setPasswordResetOtp: (state, action) => {
      state.passwordResetOtp = action.payload;
    },
    setUserRecord: (state, action) => {
      state.userRecord = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setPasswordResetUser,
  setPasswordResetOtp,
  setUserRecord,
  setIsLoggedIn,
} = UserSlice.actions;

export default UserSlice.reducer;
