import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import linkSent from "../../assets/img/linkSent.svg";
import wave from "../../assets/img/wave.svg";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const SignUpVerification = (res) => {
  const classes = useStyles();
  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h4"
            className={clsx(classes.textColor, " text-center font-extrabold")}
          >
            Verification
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.textColor, "text-center font-extrabold")}
          >
            Link Sent
          </Typography>
        </div>
        <div className="mx-auto">
          <img src={linkSent} alt="Link Sent" />
        </div>
        <Typography variant="p" className=" mt-3 text-sm">
          Confirm your account via the emailed link. Upon verification, you will
          be redirected to website. You can close this tab.
        </Typography>

        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default SignUpVerification;
