import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import Title from "../../components/Title";
import axios from "../../MyAxios";
import { useDispatch, useSelector } from "react-redux";
import {
  GROUP_RESOURCES,
  ROOT,
  SAVE_RESOURCE,
  UNSAVE_RESOURCE,
} from "../../constants/api";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import TabPane from "@components/TabPane";
import dayjs from "dayjs";
import { MoreHoriz } from "@mui/icons-material";
import GroupModal from "@components/GroupModal";
import save from "@images/saveGreen.svg";
import share from "@images/shareGreen.svg";
import link from "@images/resourceLink.svg";
import clsx from "clsx";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  bgGrey: {
    backgroundColor: "#efefef",
    color: "#000",
  },
}));

const Resources = (res) => {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const { state } = useLocation();
  const { groupId } = state;
  const [mediaResources, setMediaResources] = useState([]);
  const [linkResources, setLinkResources] = useState([]);
  const [selected, setSelected] = useState();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tabs = ["Media", "Text & Links"];

  const getResources = async () => {
    try {
      const res = await axios.get(`${GROUP_RESOURCES}/${groupId}`);
      setMediaResources(res.data.mediaResources);
      setLinkResources(res.data.linkResources);
      console.log(res.data);
    } catch (error) {}
  };
  const options = [
    {
      id: 1,
      name: "Share",
      img: share,
    },
    {
      id: 2,
      name: "Save",
      img: save,
      action: async () => {
        try {
          await axios.post(SAVE_RESOURCE, { id: selected.id });
          toast.success("Saved Successfully");
          setOpen(false);
        } catch (error) {}
      },
    },
    {
      id: 3,
      name: "Unsave",
      img: save,
      action: async () => {
        try {
          await axios.post(UNSAVE_RESOURCE, { id: selected.id });
          toast.success("Unsaved Successfully");
          setOpen(false);
        } catch (error) {}
      },
    },
  ];

  useEffect(() => {
    getResources();
  }, [groupId]);

  const formatDate = (date) => dayjs(date).format("DD/MM/YYYY");

  return (
    <Layout>
      <Title back={ROOT}>Resources</Title>
      <TabPane tabs={tabs}>
        <div>
          {mediaResources?.map((resource) => (
            <div className="p-2">
              <Typography color={"text.dark"} className="mb-2 text-sm">
                {formatDate(resource?.createdAt)}
              </Typography>
              <div className="flex justify-between">
                <div className="flex">
                  <img
                    className="w-28 h-20 rounded-md object-contain"
                    src={resource?.media?.avatarUrl}
                    alt="Media"
                  />
                  <Typography color={"text.dark"} className="ml-2">
                    {resource?.title}
                  </Typography>
                </div>
                <MoreHoriz
                  className=" cursor-pointer"
                  sx={{ color: "text.dark" }}
                  onClick={() => {
                    setSelected(resource);
                    setOpen(true);
                  }}
                />
              </div>
            </div>
          ))}
          <GroupModal open={open} setOpen={setOpen} options={options} />
        </div>
        <div>
          {linkResources.map((resource) => (
            <div className="p-2">
              <Typography color={"text.dark"} className="mb-2 text-sm">
                {formatDate(resource?.createdAt)}
              </Typography>
              <div className="flex justify-between">
                <div
                  className={clsx(
                    `leading-1.5 w-5/6 my-2 flex flex-col p-4 rounded-lg`,
                    classes.bgGrey
                  )}
                >
                  <p className="text-sm font-bold">{resource?.title}</p>
                  <p className="text-sm font-normal">{resource?.description}</p>
                  <p className="text-sm font-normal flex items-center">
                    <img src={link} className="mr-1 mt-1" alt="Link" />{" "}
                    {resource?.link}
                  </p>
                </div>
                <MoreHoriz
                  className=" cursor-pointer"
                  sx={{ color: "text.dark" }}
                  onClick={() => {
                    setSelected(resource);
                    setOpen(true);
                  }}
                />
              </div>
            </div>
          ))}
          <GroupModal open={open} setOpen={setOpen} options={options} />
        </div>
      </TabPane>
    </Layout>
  );
};

export default Resources;
