import React, { useEffect, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import Post from "../../components/Post";
import axios from "../../MyAxios";
import { useDispatch } from "react-redux";
import {
  CONNECTION_POSTS,
  CREATE_POST,
  GET_LOGGED_USER,
  GET_SAVED_POSTS,
} from "../../constants/api";
import Layout from "../../Layout";
import { Link } from "react-router-dom";
import { setUserRecord } from "../../redux/userSlice";
import useLoggedIn from "../../hooks/useLoggedIn";
import addPost2 from "../../assets/img/addPost2.svg";
import { useTheme } from "@emotion/react";

function HomePage() {
  useLoggedIn();
  const [posts, setPosts] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const getSavedItems = async () => {
      try {
        await axios.get(GET_SAVED_POSTS).then((res) => {
          setSavedItems(res.data);
          console.log(res.data);
        });
      } catch (error) {}
    };
    getSavedItems();
  }, []);

  const GetPosts = async () => {
    try {
      await axios.get(`${CONNECTION_POSTS}/${page}`).then((res) => {
        console.log(res.data);
        setPosts([...posts, ...res.data.posts]);
        setTotalPages(res.data.totalPages);
      });
    } catch (error) {}
  };

  const GetUser = async () => {
    try {
      await axios.get(GET_LOGGED_USER).then((res) => {
        dispatch(setUserRecord(res.data));
      });
    } catch (error) {
      console.log("Error in home page");
    }
  };

  useEffect(() => {
    GetPosts();
  }, [page]);

  useEffect(() => {
    GetUser();
  }, []);

  return (
    <Layout>
      <div>
        {posts?.map((item) => (
          <div
            key={item?.id}
            style={{ borderColor: theme.palette.primary.main }}
            className=" border-b-2"
          >
            <Post post={item} savedItems={savedItems} comment={true} />
          </div>
        ))}
        {page < totalPages && (
          <Typography
            onClick={() => setPage(page + 1)}
            className=" text-center"
          >
            Show More
          </Typography>
        )}
      </div>
      <Link to={CREATE_POST} className="fixed bottom-10 right-5">
        <Avatar className=" h-12 w-12" src={addPost2} />
      </Link>
    </Layout>
  );
}

export default HomePage;
