// Apis for frontend

export const CREATE_GROUP = `/create/group`;

export const LOGIN = `/login`;

export const USERNAME = `/username`;

export const SIGNUP = `/signup`;

export const RESET_PASSWORD = `/reset-password`;

export const ROOT = `/`;

export const PROFILE = `/profile`;

export const PROFILE_UPDATE = `/profile`;

export const GET_USER_CHAT = `/user/chat/`; // with user id

export const BLOCK_USER = "/user/block";
export const UNBLOCK_USER = "/user/unblock";

export const GET_BLOCKED_USERS = "/user/block";

export const GROUP_SELECT_COMMUNITY = `/create/group/select/community`;
export const SEARCH_GROUPS = "/group/search";
export const ADD_COMMUNITY_CONNECTION = `/add-community-connection`;

export const SELECT_COMMUNITY = `/select-community`;
export const SELECT_GROUP = `/select-group`;

export const SELECT_CONNECTION = `/select-connection`;

export const LINK_SENT = `/link-sent`;

export const GROUP_MANAGEMENT = `/group/management`;

export const STREAM_MANAGEMENT = `/stream/management`;

export const PERSONEL_DETAILS = `/enter/person-details`;

export const ENTER_OTP = `/enter-otp`;

export const ENTER_PASSWORD = `/enter-password`;

export const NEW_CHAT = `/new/chat`;

export const GROUP_INFO = `/group/info`;

export const POST = `/post`;

export const GET_ALL_USERS = `/users`;

export const GET_USERS_ROOMS = `/chat-rooms`;

export const ADD_CONNECTION = `/user/add/connection`;

export const CHECK_EMAIL = `/enter/person-details`;

export const GET_LOGGED_USER = `/user`;

export const CREATE_POST = `/post/create`;

export const GET_POSTS = `/posts`;

export const GET_USER_POSTS = `/user/posts`;

export const GET_COMMUNITIES = `/get/communities`;

export const VERIFY_OTP = `/verify-otp`;

export const CHANGE_PASSWORD = `/change-password`;

export const UPLOAD_FILE = `/upload-file`;

export const POST_DELETE = `/post/delete`;

export const EDIT_PROFILE = `/edit/profile`;

export const GET_COMMENTS = `/get/comments`;

export const POST_COMMENT = `/post/comment`;

export const DELETE_POST = `/post/delete`;

export const GET_SAVED_POSTS = `/saved/posts`;

export const GET_SAVED_MESSAGES = `/saved/messages`;

export const GET_SAVED_RESOURCES = `/saved/resources`;

export const SEND_MESSAGE = `/user/send/message`;

export const DELETE_CHAT = `/user/delete/chat`;

export const DELETE_MESSAGE = `/user/delete/message`;

export const SAVE_MESSAGE = `/user/save/message`;

export const UNSAVE_MESSAGE = `/user/unsave/message`;

export const SAVE_RESOURCE = `/user/save/resource`;

export const UNSAVE_RESOURCE = `/user/unsave/resource`;

export const GET_MESSAGES = `/user/messages`;

export const GET_GROUPS = `/groups`;
export const GET_GROUPS_BY_MEMBER = "groups-by-member";
export const GET_GROUP = `/get/group`;

export const GET_TOKEN = `/get/token`;

export const GROUP_ADD_MEMBERS = "/group/add/member";

export const GROUP_ADD_MODERATOR = "/group/add/moderator";

export const GROUP_REMOVE_MEMBER = "/group/remove/member";

export const GROUP_CREATE_POST = "/group/create/post";

export const GET_GROUP_POSTS = "/get/group/posts";

export const EDIT_GROUP = "/edit/group";

export const GROUP_ADD_RESOURCES = "/group/add/resources";

export const GROUP_RESOURCES = "/group/resources";

export const REPORT_POST = "/report/post";

export const REPORT_USER = "/report/user";

export const REPORT_MESSAGE = "/report/message";

export const REPORT_GROUP = "/report/group";

export const REPORT_COMMENT = "/report/comment";

export const REPORT_FORM = "/report";

export const SEARCH = "/search";

// NOTIFICATIONS

export const REQUEST_CONNECTION = "/notification/request/connection";
export const GET_NOTIFICATIONS = "/notification/all";
export const READ_NOTIFICATION = "/notification/read";
export const DELETE_NOTIFICATION = "/notification/delete";
export const COUNT_UNREAD_NOTIFICATIONS = "/notification/unread/count";
// CONNECTIONS
export const GET_CONNECTIONS = "/connections/all";
export const APPROVE_CONNECTION = "/connections/approve";
export const REMOVE_CONNECTION = "/connections/remove";
export const CONNECTION_POSTS = "/connections/posts";
