import api from "@apiClient";
import useTable from "@hooks/useTable";
import React, { useState } from "react";
import Table from "@components/admin/Table";
import MyAvatar from "@components/admin/MyAvatar";
import PageNavigator from "@components/admin/PageNavigator";
import ReportFilter from "@components/admin/ReportFilter";
import { ReportFilters, getSelectedItems } from "@helpers";
import PopperList from "@components/admin/PopperList";
import PopperWrapper from "@components/admin/PopperWrapper";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

import MailAvatar from "@images/mailAvatar.svg";
import GoogleAvatar from "@images/googleAvatar.svg";
import FacebookAvatar from "@images/facebookAvatar.svg";
import AppleAvatar from "@images/appleAvatar.svg";
import FeedbackDetail from "@components/admin/FeedbackDetail";
import { toast } from "react-toastify";
import clsx from "clsx";
import FeedbackResponse from "@components/admin/FeedbackResponse";

const providerAvatars = {
  MAIL: MailAvatar,
  GOOGLE: GoogleAvatar,
  FACEBOOK: FacebookAvatar,
  APPLE: AppleAvatar,
};

export default function Feedbacks() {
  const [activeReportFilter, setActiveReportFilter] = useState(
    ReportFilters.UNRESOLVED
  );
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [selectedResponseFeedback, setSelectedResponseFeedback] =
    useState(null);

  const {
    setPage,
    page,
    totalPages,
    count,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setReload,
    setFilters,
  } = useTable({
    getData: api.getFeedbacks,
    pageSize: 10,
    initialFilters: {
      status: activeReportFilter,
    },
  });

  const resolveFeedbacks = (ids) => {
    api
      .resolveFeedbacks({ ids })
      .then((resolvedReports) => {
        setSelectedFeedback(null);
        toast.success(
          `${resolvedReports.length} Feedback(s) Resolved Successfully`
        );
        setReload((r) => r + 1);
      })
      .catch(() => {});
  };

  const fields = [
    {
      columnName: "Name",
      render: (item) => (
        <MyAvatar
          src={item.user?.media?.avatarUrl}
          name={item.user?.firstName + " " + item.user?.lastName}
        />
      ),
    },
    {
      columnName: "username",
      render: (item) => item.user?.userName,
    },
    {
      columnName: "Email",
      render: (item) => item.user?.emailAddress,
    },
    {
      columnName: "Provider",
      render: (item) => (
        <img
          src={providerAvatars[item.provider] || providerAvatars["MAIL"]}
          alt="provider"
        />
      ),
    },
    {
      columnName: "Feedback",
      render: (item) => item.detail,
    },
    {
      columnName: "Status",
      field: "status",
      render: (item) => item.status,
    },
    {
      columnName: "Date",
      field: "createdAt",
      render: (item) => new Date(item.createdAt).toLocaleDateString(),
    },
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList
            items={[
              {
                label: "View Details",
                onClick: () => setSelectedFeedback(item),
              },
              {
                label: "Mark as Resolved",
                onClick: () => resolveFeedbacks([item.id]),
              },
              {
                label: "Respond",
                onClick: () => {
                  setSelectedFeedback(null);
                  setSelectedResponseFeedback(item);
                },
              },
            ]}
          />
        </PopperWrapper>
      ),
    },
  ];

  const reportFilterButtons = Object.values(ReportFilters).map((filter) => {
    return {
      label: filter,
      onClick: () => {
        setActiveReportFilter(filter);
        setFilters({ status: filter });
      },
    };
  });

  const modalActionButtons = [
    {
      children: "Resolve",
      onClick: () => resolveFeedbacks([selectedFeedback.id]),
    },
    {
      children: "Respond",
      onClick: () => {
        setSelectedFeedback(null);
        setSelectedResponseFeedback(selectedFeedback);
      },
    },
  ];

  const batchActionButtons = [
    {
      children: "Resolve",
      onClick: () => resolveFeedbacks(getSelectedItems(checked)),
    },
  ];

  if (loading) return <CircularProgress />;
  return (
    <div>
      <div className="flex items-center gap-2">
        <ReportFilter
          count={count}
          activeReportFilter={activeReportFilter}
          reportFilterButtons={reportFilterButtons}
        />
        <div
          className={clsx(
            Object.values(checked).filter((val) => val).length === 0 &&
              "hidden",
            "ml-auto flex items-center gap-2"
          )}
        >
          {batchActionButtons.map((button, index) => (
            <Button variant="contained" key={index} {...button} />
          ))}
        </div>
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />
      <PageNavigator
        totalPages={totalPages}
        setPage={setPage}
        page={page}
        setLimit={setLimit}
        limit={limit}
      />
      {selectedFeedback && (
        <FeedbackDetail
          feedback={selectedFeedback}
          setFeedback={setSelectedFeedback}
          actionButtons={modalActionButtons}
        />
      )}

      {selectedResponseFeedback && (
        <FeedbackResponse
          feedback={selectedResponseFeedback}
          setFeedback={setSelectedResponseFeedback}
        />
      )}
    </div>
  );
}
