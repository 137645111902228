import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import postSlice from "./postSlice";
import groupSlice from "./groupSlice";
import adminSlice from "./adminSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    post: postSlice,
    group: groupSlice,
    admin: adminSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
