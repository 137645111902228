import React from "react";
import Layout from "../../Layout";
import MyGroups from "../../components/MyGroups";
import TabPane from "../../components/TabPane";
import Title from "../../components/Title";
import { ROOT } from "../../constants/api";
import useLoggedIn from "../../hooks/useLoggedIn";

function GroupManagement() {
  useLoggedIn();
  const tabs = ["My Groups", "Admin in others"];
  return (
    <Layout>
      <Title back={ROOT}>Group Management</Title>
      <TabPane tabs={tabs}>
        <div>
          <MyGroups />
        </div>
        <div>
          <MyGroups />
        </div>
      </TabPane>
    </Layout>
  );
}

export default GroupManagement;
