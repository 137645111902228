import PopperWrapper from "./PopperWrapper";
import { IconButton, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import PopperList from "./PopperList";

export default function DateFilter({ dateFilters, count, activeDateFilter }) {
  return (
    <div className="flex items-center gap-1">
      <Typography fontWeight="bold">{activeDateFilter}</Typography>
      <Typography fontWeight="bold">({count})</Typography>
      <PopperWrapper id="date-filter">
        <IconButton>
          <ArrowDropDown />
        </IconButton>

        <PopperList items={dateFilters} />
      </PopperWrapper>
    </div>
  );
}
