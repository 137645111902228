import Layout from "../../Layout";
import Title from "../../components/Title";
import {
  REPORT_COMMENT,
  REPORT_GROUP,
  REPORT_MESSAGE,
  REPORT_POST,
  REPORT_USER,
  ROOT,
} from "../../constants/api";
import pana from "../../assets/img/pana.svg";
import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import axios from "../../MyAxios";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
}));

const ReportForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, report } = state;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("reason", data.description);
    if (report === "post") {
      try {
        formData.append("postReported", id);
        await axios.post(REPORT_POST, formData);
        toast.success("Post reported successfully");
        navigate(-1);
      } catch (error) {}
    } else if (report === "user") {
      try {
        formData.append("userReported", id);
        await axios.post(REPORT_USER, formData);
        toast.success("User reported successfully");
        navigate(-1);
      } catch (error) {}
    } else if (report === "group") {
      try {
        formData.append("groupReported", id);
        await axios.post(REPORT_GROUP, formData);
        toast.success("Group reported successfully");
        navigate(-1);
      } catch (error) {}
    } else if (report === "message") {
      try {
        formData.append("messageReported", id);
        await axios.post(REPORT_MESSAGE, formData);
        toast.success("Message reported successfully");
        navigate(-1);
      } catch (error) {}
    } else if (report === "comment") {
      try {
        formData.append("commentReported", id);
        await axios.post(REPORT_COMMENT, formData);
        toast.success("Comment reported successfully");
        navigate(-1);
      } catch (error) {}
    }
  };
  return (
    <Layout>
      <Title>Report</Title>
      <div className="mx-auto mt-24 mb-4">
        <img src={pana} alt="Report" />
      </div>
      <div className="mx-auto text-center">
        <Typography variant="h5" className=" font-bold" color="primary.main">
          Tell us about the problem
        </Typography>
        <Typography color={"text.dark"}>Report any concerns</Typography>
      </div>

      <div className="m-auto mt-5 flex w-5/6 flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Add Description"
              {...register("description", {
                required: "Description is required",
              })}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className=" flex items-end justify-end">
            <button
              type="submit"
              className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ReportForm;
