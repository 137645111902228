import { IconButton, CircularProgress } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import Table from "@components/admin/Table";
import PageNavigator from "@components/admin/PageNavigator";
import SearchBar from "@components/admin/SearchBar";
import apiClient from "@apiClient";
import AdminLayout from "./AdminLayout";
import useSetActiveLink from "@hooks/useSetActiveLink";
import useTable from "@hooks/useTable";
import MyAvatar from "@components/admin/MyAvatar";
import DateFilter from "@components/admin/DateFilter";
import clsx from "clsx";
import PopperWrapper from "@components/admin/PopperWrapper";
import PopperList from "@components/admin/PopperList";
import { useState } from "react";
import SideOptions from "@components/admin/SideOptions";
import useAdminLoggedIn from "@hooks/useAdminLoggedIn";
import MailAvatar from "@images/mailAvatar.svg";
import GoogleAvatar from "@images/googleAvatar.svg";
import FacebookAvatar from "@images/facebookAvatar.svg";
import AppleAvatar from "@images/appleAvatar.svg";
import FeedBackIcon from "@images/feedback.svg";
import UsersIcon from "@images/users.svg";
import useDateFilters from "@hooks/useDateFilters";
import useActionFilters from "@hooks/useActionFilters";
import { createDateColumn, getSelectedItems } from "@helpers";
import Feedbacks from "./Feedbacks";

const providerAvatars = {
  MAIL: MailAvatar,
  GOOGLE: GoogleAvatar,
  FACEBOOK: FacebookAvatar,
  APPLE: AppleAvatar,
};

function Users() {
  useAdminLoggedIn();
  useSetActiveLink(1);
  const {
    setReload,
    setPage,
    page,
    totalPages,
    count,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setDateFilters,
    setFilters,
  } = useTable({
    getData: apiClient.getUsers,
    pageSize: 10,
  });
  const [showFeedback, setShowFeedBack] = useState(false);

  const {
    activeFilter,
    getActions,
    getActionLinks,
    setActiveFilter,
    getActionButtons,
    getConfirmationModals,
  } = useActionFilters({
    apiDelete: apiClient.deleteUsers,
    apiDisable: apiClient.disableUsers,
    apiReinstate: apiClient.reinstateUsers,
    setFilters,
    onLinkClick: () => {
      setShowFeedBack(false);
    },
    getSelectedItems: () => getSelectedItems(checked),
    setReload,
  });

  // PROPS FOR FILTERS
  const { dateFilters, activeDateFilter } = useDateFilters({
    setDateFilters,
    prefix: "Joined",
    activeFilter: activeFilter,
  });

  // PROPS FOR TABLE
  const fields = [
    {
      columnName: "Name",
      field: "firstName",
      render: (item) => (
        <MyAvatar
          src={item.media?.avatarUrl}
          name={`${item.firstName} ${item.lastName}`}
        />
      ),
    },
    {
      columnName: "Username",
      field: "userName",
      render: (item) => item.userName,
    },
    {
      columnName: "Email",
      field: "emailAddress",
      render: (item) => item.emailAddress,
    },
    {
      columnName: "Provider",
      render: (item) => (
        <img
          src={providerAvatars[item.provider] || providerAvatars["MAIL"]}
          alt="provider"
        />
      ),
    },
    createDateColumn(activeFilter),
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList items={getActions(item)} />
        </PopperWrapper>
      ),
    },
  ];

  // Side Options Links
  const links = [
    ...getActionLinks(),
    {
      text: "Feedback",
      icon: <img src={FeedBackIcon} alt="feedback" />,
      onClick: () => {
        setShowFeedBack(true);
        setActiveFilter("Feedback");
      },
    },
  ];

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }
  return (
    <AdminLayout>
      <div className="h-full">
        <div className="flex h-full">
          <SideOptions
            headerText={"Users"}
            headerIcon={<img src={UsersIcon} alt="users" />}
            links={links}
            activeFilter={activeFilter}
          />
          {!showFeedback && (
            <div className="grow pl-6 pt-6">
              <div className="flex items-center gap-2">
                <DateFilter
                  dateFilters={dateFilters}
                  count={count}
                  activeDateFilter={activeDateFilter}
                />
                <SearchBar setQuery={setQuery} query={query} />
                <div
                  className={clsx(
                    Object.values(checked).filter((val) => val).length === 0 &&
                      "hidden",
                    "ml-auto flex items-center gap-2"
                  )}
                >
                  {getActionButtons()}
                </div>
              </div>
              <Table
                data={data}
                fields={fields}
                checked={checked}
                setChecked={setChecked}
                setSortColumns={setSortColumns}
                sortColumns={sortColumns}
              />
              <PageNavigator
                totalPages={totalPages}
                setPage={setPage}
                page={page}
                setLimit={setLimit}
                limit={limit}
              />
            </div>
          )}
          {showFeedback && (
            <div className="grow pl-6 pt-6">
              <Feedbacks />
            </div>
          )}
        </div>

        {/* Confirmation Modals */}
        {getConfirmationModals()}
      </div>
    </AdminLayout>
  );
}

export default Users;
