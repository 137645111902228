import { useEffect } from "react";
import { setActiveLink } from "@redux/adminSlice";
import { useDispatch } from "react-redux";

const useSetActiveLink = (linkNumber) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveLink(linkNumber - 1));
  }, [linkNumber]);
};

export default useSetActiveLink;
