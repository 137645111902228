import { useState } from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import { useDetectClickOutside } from "react-detect-click-outside";

export default function PopperWrapper({ id, children }) {
  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const uniqueId = canBeOpen ? id : undefined;
  return (
    <div>
      <div
        aria-describedby={id}
        ref={ref}
        onClick={handleClick}
        className="inline"
      >
        {children[0]}
      </div>
      <Popper id={uniqueId} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div className="mt-2">{children[1]}</div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
