import api from "@apiClient";
import useTable from "@hooks/useTable";
import React, { useState } from "react";
import Table from "@components/admin/Table";
import MyAvatar from "@components/admin/MyAvatar";
import PageNavigator from "@components/admin/PageNavigator";
import ReportFilter from "@components/admin/ReportFilter";
import { ReportFilters } from "@helpers";
import PopperList from "@components/admin/PopperList";
import PopperWrapper from "@components/admin/PopperWrapper";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import clsx from "clsx";
import useReportActions from "@hooks/useReportActions";
import GroupReportDetail from "@components/admin/ReportDetails/GroupReportDetail";
export default function GroupReports() {
  const [activeReportFilter, setActiveReportFilter] = useState(
    ReportFilters.UNRESOLVED
  );
  const [selectedGroupReport, setSelectedGroupReport] = useState(null);

  const {
    setPage,
    page,
    totalPages,
    count,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setReload,
    setFilters,
  } = useTable({
    getData: api.getGroupReports,
    pageSize: 10,
    initialFilters: {
      status: activeReportFilter,
    },
  });
  const { actionButtons, selectedActionButtons, getActionLinks, warningModal } =
    useReportActions({
      reloadPage: () => setReload((reload) => reload + 1),
      hideModal: () => setSelectedGroupReport(null),
      selectedReport: selectedGroupReport,
      getGroupFromReport: (report) => report.groupReported,
      checked,
      data,
    });

  const fields = [
    {
      columnName: "Name",
      render: (item) => (
        <MyAvatar
          src={item.groupReported?.icon?.avatarUrl}
          name={item.groupReported?.name}
        />
      ),
    },
    {
      columnName: "Admin",
      render: (item) =>
        item.groupReported?.admin.firstName +
        " " +
        item.groupReported?.admin.lastName,
    },
    {
      columnName: "username",
      render: (item) => item.groupReported?.admin.userName,
    },
    {
      columnName: "Reporter",
      render: (item) =>
        item.reportedBy.firstName + " " + item.reportedBy.lastName,
    },
    {
      columnName: "Description",
      field: "reason",
      render: (item) => item.reason,
    },
    {
      columnName: "Status",
      field: "status",
      render: (item) => item.status,
    },
    {
      columnName: "Date",
      field: "createdAt",
      render: (item) => new Date(item.createdAt).toLocaleDateString(),
    },
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList
            items={[
              {
                label: "View Details",
                onClick: () => setSelectedGroupReport(item),
              },
              ...getActionLinks(item),
            ]}
          />
        </PopperWrapper>
      ),
    },
  ];

  const reportFilterButtons = Object.values(ReportFilters).map((filter) => {
    return {
      label: filter,
      onClick: () => {
        setActiveReportFilter(filter);
        setFilters({ status: filter });
      },
    };
  });

  if (loading) return <CircularProgress />;
  return (
    <div>
      <div className="flex items-center gap-2">
        <ReportFilter
          count={count}
          activeReportFilter={activeReportFilter}
          reportFilterButtons={reportFilterButtons}
        />
        <div
          className={clsx(
            Object.values(checked).filter((val) => val).length === 0 &&
              "hidden",
            "ml-auto flex items-center gap-2"
          )}
        >
          {selectedActionButtons.map((button, index) => (
            <Button variant="contained" key={index} {...button} />
          ))}
        </div>
      </div>
      <Table
        data={data}
        fields={fields}
        checked={checked}
        setChecked={setChecked}
        setSortColumns={setSortColumns}
        sortColumns={sortColumns}
      />
      <PageNavigator
        totalPages={totalPages}
        setPage={setPage}
        page={page}
        setLimit={setLimit}
        limit={limit}
      />
      {selectedGroupReport && (
        <GroupReportDetail
          groupReport={selectedGroupReport}
          setGroupReport={setSelectedGroupReport}
          actionButtons={actionButtons}
        />
      )}
      {warningModal()}
    </div>
  );
}
