import React from 'react'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  bgGreen: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  bgGrey: {
    backgroundColor: '#efefef',
    color: '#000',
  },
  textGrey: {
    color: '#626262',
  },
  colorGreen: {
    backgroundColor: '#e5f8f1',
  },
}))

function CommentText({ children }) {
  const classes = useStyles()
  return (
    <div>
      <div className={clsx('flex justify-start')}>
        <div className="flex max-w-xs flex-col">
          <div
            className={clsx(
              `leading-1.5 mt-2 flex flex-col rounded-e-xl  rounded-ss-xl p-4`,
              classes.bgGrey
            )}
          >
            <p className="text-sm font-normal">{children}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentText
