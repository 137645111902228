import LoginView from "../pages/entrance/Login";
import SignupDetails from "../pages/entrance/SignupDetails";
import Signup from "../pages/entrance/SignupView";
import Username from "../pages/entrance/Username";
import SignUpVerification from "../pages/entrance/SignupVerification";
import ResetPassword from "../pages/passwordReset/ResetPassword";
import EnterOTP from "../pages/passwordReset/EnterOTP";
import EnterPassword from "../pages/passwordReset/EnterPassword";
import Profile from "../pages/profile/Profile";
import EditProfile from "../pages/profile/EditProfile";
import CreatePost from "../pages/posts/CreatePost";
import AddComCon from "../pages/posts/AddComCon";
import SelectCom from "../pages/posts/SelectCom";
import SelectConn from "../pages/posts/SelectConn";
import ViewPost from "../pages/posts/ViewPost";
import UserChat from "../pages/chat/UserChat";
import Chats from "../pages/chat/Chats";
import adminRoutes from "./admin.routes";
import Save from "../pages/save/Save";
import HomePage from "../pages/home/HomePage";
import CreateGroup from "../pages/group/CreateGroup";
import GroupInfo from "../pages/group/GroupInfo";
import CreateGroupDetails from "../pages/group/CreateGroupDetails";
import GroupManagement from "../pages/group/GroupManagement";
import { createBrowserRouter } from "react-router-dom";
import NewChat from "@pages/chat/NewChat";
import StreamManagement from "@pages/home/StreamManagement";
import AuthCallback from "@pages/entrance/AuthCallback";
import ReportForm from "@pages/report/ReportForm";
import AddResources from "@pages/group/AddResources";
import ContactForm from "@pages/contact/ContactUs";
import Blocked from "@pages/blocked/Blocked";
import Resources from "@pages/group/Resources";
import AddMembers from "@pages/group/AddMembers";
import CreateGroupPost from "@pages/group/CreateGroupPost";
import EditGroup from "@pages/group/EditGroup";
import Search from "@pages/search/Search";
import Notifications from "@pages/notifications/Notifications";
import SelectGroup from "@pages/posts/SelectGroup";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/stream",
    element: <HomePage />,
  },
  {
    path: "/login",
    element: <LoginView />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/enter/person-details",
    element: <SignupDetails />,
  },
  {
    path: "/username",
    element: <Username />,
  },
  {
    path: "/link-sent",
    element: <SignUpVerification />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/enter-otp",
    element: <EnterOTP />,
  },
  {
    path: "/enter-password",
    element: <EnterPassword />,
  },
  {
    path: "/enter-password",
    element: <EnterPassword />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/edit/profile",
    element: <EditProfile />,
  },
  {
    path: "/post/create",
    element: <CreatePost />,
  },
  {
    path: "/add-community-connection",
    element: <AddComCon />,
  },
  {
    path: "/select-community",
    element: <SelectCom />,
  },
  {
    path: "/select-group",
    element: <SelectGroup />,
  },
  {
    path: "/select-connection",
    element: <SelectConn />,
  },
  {
    path: "/post",
    element: <ViewPost />,
  },
  {
    path: "/user/chat",
    element: <UserChat />,
  },
  {
    path: "/chats",
    element: <Chats />,
  },
  {
    path: "/new/chat",
    element: <NewChat />,
  },
  {
    path: "/save",
    element: <Save />,
  },
  {
    path: "/blocked",
    element: <Blocked />,
  },
  {
    path: "/create/group/select/community",
    element: <CreateGroup />,
  },
  {
    path: "/edit/group",
    element: <EditGroup />,
  },
  {
    path: "/group/info",
    element: <GroupInfo />,
  },
  {
    path: "/group/resources",
    element: <Resources />,
  },
  {
    path: "/create/group",
    element: <CreateGroupDetails />,
  },
  {
    path: "/group/management",
    element: <GroupManagement />,
  },
  {
    path: "/stream/management",
    element: <StreamManagement />,
  },
  {
    path: "/auth/callback/:token",
    element: <AuthCallback />,
  },
  {
    path: "/report",
    element: <ReportForm />,
  },
  {
    path: "/group/add/resources",
    element: <AddResources />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/group/add/member",
    element: <AddMembers />,
  },
  {
    path: "/group/create/post",
    element: <CreateGroupPost />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/notifications",
    element: <Notifications />,
  },
  ...adminRoutes,
]);

export default router;
