import React, { useEffect, useRef, useState } from "react";
import { Avatar, Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/Title";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { EDIT_PROFILE, PROFILE_UPDATE, ROOT } from "../../constants/api";
import TabPane from "../../components/TabPane";
import camera from "../../assets/img/camera.svg";
import { setUserRecord } from "../../redux/userSlice";
import Layout from "../../Layout";
import useLoggedIn from "../../hooks/useLoggedIn";
import MyConnections from "@components/Connections";
import MyGroups from "@components/MyGroups";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function Profile() {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [file, setProfilePic] = useState();
  const { userRecord } = useSelector((state) => state.user);
  const fileInput = useRef(null);
  useEffect(() => {
    localStorage.removeItem("formData");
  }, []);
  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setProfilePic(e.target.files[0]);
    }
  };

  useEffect(() => {
    const uploadFile = async () => {
      try {
        if (file) {
          toast.info("Uploading Profile Pic");
          const formData = new FormData();
          formData.append("id", userRecord.id);
          formData.append("mediaId", userRecord.media?.id || "");
          formData.append("file", file);
          await axios.post(PROFILE_UPDATE, formData).then((res) => {
            console.log(res);
            toast.success("Profile Pic updated Successfully");
            dispatch(setUserRecord(res.data));
          });
        }
      } catch (error) {}
    };
    uploadFile();
  }, [file]);

  const tabs = ["Communities", "Groups", "Connections"];

  return (
    <Layout>
      <Title back={ROOT}>Profile</Title>
      <div className=" flex flex-row px-5 py-3">
        <form
          className=" relative"
          method="post"
          encType="multipart/form-data"
          onClick={() => fileInput.current.click()}
        >
          <Avatar
            className=" h-20 w-20 object-contain"
            src={userRecord?.media?.avatarUrl}
          />
          <img
            className=" absolute bottom-0 right-0"
            src={camera}
            alt="Camera"
          />
          <input
            onChange={onChange}
            ref={fileInput}
            type="file"
            className="hidden"
          />
        </form>
        <div className="p-2 pl-4">
          <Typography
            variant="h6"
            className={clsx(classes.textGrey, "font-bold")}
          >
            {userRecord.firstName} {userRecord.lastName}
          </Typography>
          <Typography className={clsx(classes.textGrey, " leading-none")}>
            <small>{userRecord.userName}</small>
          </Typography>
          <div>
            <Link to={EDIT_PROFILE}>
              <button
                className={clsx(
                  classes.textColor,
                  "cursor-pointer text-sm font-bold underline"
                )}
              >
                Edit Profile
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="px-5">
        <Typography className={clsx(classes.textGrey, "font-semibold")}>
          About
        </Typography>
        <Typography className={clsx(classes.textGrey, " text-sm")}>
          {userRecord?.about}
        </Typography>
      </div>
      <TabPane tabs={tabs}>
        <div>Communities</div>
        <div>
          <MyGroups />
        </div>
        <div>
          <MyConnections />
        </div>
      </TabPane>
    </Layout>
  );
}

export default Profile;
