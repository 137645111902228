import { useEffect, useState } from "react";
import Layout from "../../Layout";
import Title from "../../components/Title";
import useLoggedIn from "../../hooks/useLoggedIn";
import axios from "@MyAxios";
import {
  APPROVE_CONNECTION,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  READ_NOTIFICATION,
} from "@constants/api";
import MyAvatar from "@components/admin/MyAvatar";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";

function NotificationItem({ notification, fromUser, deleteNotification }) {
  const getNotificationMessage = () => {
    switch (notification.type) {
      case "Connection":
        return `${fromUser?.firstName} ${fromUser?.lastName} sent you a connection request`;
      default:
        return "New Notification";
    }
  };

  const getNotificationButtons = () => {
    switch (notification.type) {
      case "Connection":
        return [
          {
            children: "Approve",
            onClick: () => {
              axios
                .post(APPROVE_CONNECTION, { connectionId: fromUser.id })
                .then(() => {
                  deleteNotification(notification.id);
                  toast.success("Connection request approved");
                })
                .catch(() => {});
            },
          },
          {
            children: "Reject",
            color: "error",
            onClick: () => {
              deleteNotification(notification.id);
            },
          },
        ];
      default:
        return null;
    }
  };
  return (
    <div className="rounded-md p-3 bg-gray-200 mt-4">
      <div className="mb-2">{getNotificationMessage()}</div>
      <div>
        {getNotificationButtons().map((button, key) => (
          <Button
            key={key}
            sx={{ textTransform: "none", fontWeight: "bold" }}
            className="text-sm"
            {...button}
          />
        ))}
      </div>
    </div>
  );
}

function NotificationsDropDown({
  notificationGroup,
  markAsRead,
  setNotifications,
}) {
  const [open, setOpen] = useState(false);
  const fromUser = notificationGroup?.fromUser;
  const notifications = notificationGroup?.notifications;
  const unreadCount = notifications?.filter((n) => !n.isRead).length;
  const subtext =
    unreadCount > 0 ? (
      <Typography
        color={"primary.main"}
        className="text-sm"
      >{`${unreadCount} unread notifications`}</Typography>
    ) : (
      `${notifications.length} notifications`
    );

  const deleteNotification = (notificationId) => {
    axios.post(DELETE_NOTIFICATION, { id: notificationId });
    const newNotifications = notifications.filter(
      (n) => n.id !== notificationId
    );
    setNotifications(newNotifications);
  };
  if (notifications.length === 0) return null;
  return (
    <div>
      <div
        onClick={() => {
          setOpen(!open);
          markAsRead();
        }}
        className="border-b-2 border-gray-100  p-4 cursor-pointer"
      >
        <MyAvatar
          name={fromUser?.firstName + " " + fromUser?.lastName}
          subtext={subtext}
        />
      </div>
      <div className="px-4">
        {open &&
          notifications.map((n, key) => (
            <NotificationItem
              key={key}
              notification={n}
              fromUser={fromUser}
              deleteNotification={deleteNotification}
            />
          ))}
      </div>
    </div>
  );
}

export default function Notifications() {
  useLoggedIn();
  const [groupedNotifications, setGroupedNotifications] = useState([]);

  useEffect(() => {
    axios
      .get(GET_NOTIFICATIONS)
      .then((res) => {
        setGroupedNotifications(res.data);
      })
      .catch(() => {});
  }, []);

  const markAsRead = (groupId, key) => {
    axios.post(READ_NOTIFICATION, {
      id: groupId,
    });
    groupedNotifications[key].notifications.map((n) => (n.isRead = true));
    setGroupedNotifications(groupedNotifications);
  };
  const setNotifications = (newNotifications, key) => {
    const newGroupedNotifications = [...groupedNotifications];
    newGroupedNotifications[key].notifications = newNotifications;
    setGroupedNotifications(newGroupedNotifications);
  };
  return (
    <Layout>
      <Title back={"/"}>Notifications</Title>
      {groupedNotifications.map((group, key) => (
        <NotificationsDropDown
          key={key}
          notificationGroup={group}
          markAsRead={() => markAsRead(group.id, key)}
          setNotifications={(newNotifications) =>
            setNotifications(newNotifications, key)
          }
        />
      ))}
    </Layout>
  );
}
