import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import axios from "../MyAxios";
import { toast } from "react-toastify";
import { DELETE_POST, POST } from "../constants/api";
import PostButton from "./PostButton";
import { useTheme } from "@emotion/react";
import share from "../assets/img/share.svg";
import link from "../assets/img/link.svg";
import commentimg from "../assets/img/comment.svg";
import addConnection from "../assets/img/addConnection.svg";
import deleteImg from "../assets/img/deleteDark.svg";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { calTime } from "../helpers";
import { useSelector } from "react-redux";

export default function MyPost({ post, comment, setDeletedId }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userRecord } = useSelector((state) => state.user);

  const viewPost = (id) => {
    if (id && comment) {
      try {
        navigate(POST, { state: { id: id } });
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const onDelete = async (post) => {
    try {
      await axios.post(DELETE_POST, { post }).then((res) => {
        toast.success("Post Deleted Successfully");
      });
      setDeletedId(post);
    } catch (error) {}
  };
  const actions = [
    {
      id: 1,
      src: share,
      shown: true,
    },
    {
      id: 2,
      src: link,
      shown: true,
    },
    {
      id: 3,
      src: commentimg,
      onClick: () => viewPost(post?.id),
      shown: comment,
    },
  ];

  return (
    <div className="">
      <Card
        onClick={() => viewPost(post.id)}
        className={clsx(
          " z-0 mx-auto mb-5 shadow-none",
          comment && "cursor-pointer"
        )}
      >
        <CardHeader
          avatar={<Avatar src={userRecord?.media?.avatarUrl} />}
          action={
            <IconButton aria-label="settings">
              <PostButton>Edit</PostButton>
            </IconButton>
          }
          title={
            <Typography className=" text-sm">
              {`${userRecord?.firstName} ${userRecord?.lastName}`}
              <small
                style={{ color: theme.palette.text.dark }}
                className="ml-2"
              >
                {calTime(post?.createdAt)}
              </small>
            </Typography>
          }
          subheader={
            <div className="flex">
              <Typography className=" text-xs">
                {userRecord?.userName}
              </Typography>
              <img className="ml-2" src={addConnection} alt="Add Connection" />
            </div>
          }
        />
        <Typography
          variant="body2"
          className="mb-2 ml-4"
          color="text.secondary"
        >
          {post?.title}
        </Typography>
        {post?.media?.avatarUrl && (
          <CardMedia
            component="img"
            className="mx-auto h-52 w-11/12 rounded-2xl object-contain"
            image={post?.media?.avatarUrl}
            alt="Stream Media"
          />
        )}
        <CardActions
          onClick={(e) => e.stopPropagation()}
          disableSpacing
          className="mt-3 flex justify-between md:justify-center"
        >
          <div className="flex">
            {actions.map((action) => (
              <div key={action.id}>
                {action.shown && (
                  <img
                    src={action.src}
                    key={action.id}
                    className="mx-3 cursor-pointer"
                    onClick={action.onClick}
                    alt="Action"
                  />
                )}
              </div>
            ))}
          </div>
          <img
            className="mr-4 cursor-pointer"
            src={deleteImg}
            onClick={() => onDelete(post.id)}
            alt="Saved"
          />
        </CardActions>
        {comment && (
          <button onClick={() => viewPost(post.id)}>
            <Typography variant="body2" color="text.secondary" className="ml-4">
              View {post?.comments} comments
            </Typography>
          </button>
        )}
      </Card>
    </div>
  );
}
