import { IconButton, CircularProgress } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import Table from "@components/admin/Table";
import PageNavigator from "@components/admin/PageNavigator";
import SearchBar from "@components/admin/SearchBar";
import apiClient from "@apiClient";
import AdminLayout from "./AdminLayout";
import useSetActiveLink from "@hooks/useSetActiveLink";
import useTable from "@hooks/useTable";
import MyAvatar from "@components/admin/MyAvatar";
import DateFilter from "@components/admin/DateFilter";
import clsx from "clsx";
import PopperWrapper from "@components/admin/PopperWrapper";
import PopperList from "@components/admin/PopperList";
import SideOptions from "@components/admin/SideOptions";
import useAdminLoggedIn from "@hooks/useAdminLoggedIn";

import UsersIcon from "@images/users.svg";
import useDateFilters from "@hooks/useDateFilters";
import useActionFilters from "@hooks/useActionFilters";
import { createDateColumn, getSelectedItems } from "@helpers";
import { useState } from "react";
import GroupDetailModal from "@components/admin/GroupDetailModal";

function Groups() {
  useAdminLoggedIn();

  useSetActiveLink(3);

  const {
    setReload,
    setPage,
    page,
    totalPages,
    count,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setDateFilters,
    setFilters,
  } = useTable({
    getData: apiClient.getGroups,
    pageSize: 10,
  });
  const [openGroupDetails, setOpenGroupDetails] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");

  const {
    activeFilter,
    getActions,
    getActionLinks,
    getActionButtons,
    getConfirmationModals,
  } = useActionFilters({
    apiDelete: apiClient.deleteGroups,
    apiDisable: apiClient.disableGroups,
    apiReinstate: apiClient.reinstateGroups,
    setFilters,
    getSelectedItems: () => getSelectedItems(checked),
    setReload,
    customActions: [
      {
        label: "View Details",
        generateClickHandler: (item) => {
          return () => {
            setSelectedGroup(item);
            setOpenGroupDetails(true);
          };
        },
      },
    ],
  });

  const { dateFilters, activeDateFilter } = useDateFilters({
    setDateFilters,
    prefix: "Created",
    activeFilter: activeFilter,
  });

  // PROPS FOR TABLE
  const fields = [
    {
      columnName: "Name",
      field: "name",
      render: (item) => (
        <MyAvatar src={item.icon?.avatarUrl} name={item.name} />
      ),
    },
    {
      columnName: "Admin",
      render: (item) => item.admin?.firstName + " " + item.admin?.lastName,
    },
    {
      columnName: "Admin Username",
      render: (item) => item.admin?.userName,
    },
    {
      columnName: "Community",
      render: (item) => item.community?.name,
    },
    {
      columnName: "Members",
      render: (item) => item.members?.length,
    },
    createDateColumn(activeFilter),
    {
      columnName: "Actions",
      render: (item) => (
        <PopperWrapper id={item.id}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
          <PopperList items={getActions(item)} />
        </PopperWrapper>
      ),
    },
  ];

  // Side Options Links
  const links = [...getActionLinks()];
  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <CircularProgress />
      </div>
    );
  }
  return (
    <AdminLayout>
      <div className="h-full">
        <div className="flex h-full">
          <SideOptions
            headerText={"Groups"}
            headerIcon={<img src={UsersIcon} alt="users" />}
            links={links}
            activeFilter={activeFilter}
          />
          <div className="grow pl-6 pt-6">
            <div className="flex items-center gap-2">
              <DateFilter
                count={count}
                dateFilters={dateFilters}
                activeDateFilter={activeDateFilter}
              />
              <SearchBar setQuery={setQuery} query={query} />
              <div
                className={clsx(
                  Object.values(checked).filter((val) => val).length === 0 &&
                    "hidden",
                  "ml-auto flex items-center gap-2"
                )}
              >
                {getActionButtons()}
              </div>
            </div>
            <Table
              data={data}
              fields={fields}
              checked={checked}
              setChecked={setChecked}
              setSortColumns={setSortColumns}
              sortColumns={sortColumns}
            />
            <PageNavigator
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              setLimit={setLimit}
              limit={limit}
            />
          </div>
        </div>

        {/* Confirmation Modals */}
        {getConfirmationModals()}
        {/* Group Details Modal */}
        <GroupDetailModal
          open={openGroupDetails}
          setOpen={setOpenGroupDetails}
          group={selectedGroup}
          setReload={setReload}
        />
      </div>
    </AdminLayout>
  );
}

export default Groups;
