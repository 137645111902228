import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

export default function SearchBar({ setQuery, query }) {
  const [search, setSearch] = useState(query || "");
  return (
    <div className="flex justify-end gap-2">
      <TextField
        className="bg-slate-100"
        size="small"
        type="search"
        value={search}
        focused={Boolean(search)}
        onKeyUp={(e) => e.key === "Enter" && setQuery(search)}
        onChange={(e) => setSearch(e.target.value)}
        label="Search Here"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setQuery(search);
                }}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
