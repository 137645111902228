import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Avatar, Typography } from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  GROUP_MANAGEMENT,
  GROUP_SELECT_COMMUNITY,
  PROFILE,
  STREAM_MANAGEMENT,
} from "../constants/api";
import { Link, useNavigate } from "react-router-dom";
import { setIsLoggedIn } from "../redux/userSlice";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

export default function TemporaryDrawer({ open, handleClose }) {
  const { userRecord } = useSelector((state) => state.user);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    if (open) {
      handleClose();
    }
  };

  const data = [
    {
      id: 1,
      text: "View Profile",
      link: PROFILE,
    },
    {
      id: 2,
      text: "Stream Management",
      link: STREAM_MANAGEMENT,
    },
    {
      id: 3,
      text: "Group Management",
      link: GROUP_MANAGEMENT,
    },
    {
      id: 4,
      text: "Create Group",
      link: GROUP_SELECT_COMMUNITY,
    },
    {
      id: 5,
      text: "Messages",
      link: "/chats",
    },
    {
      id: 6,
      text: "Saved Content",
      link: "/save",
    },
    {
      id: 7,
      text: "Blocked Content",
      link: "/blocked",
    },
  ];

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setIsLoggedIn(false));
    navigate("/login", { replace: true });
  };
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <div className=" flex flex-row items-center py-4 pl-5">
        <div>
          <Avatar
            className=" h-10 w-10 rounded-full"
            src={userRecord?.media?.avatarUrl}
          />
        </div>
        <div className="p-2 pl-4">
          <Typography className={clsx(classes.textGrey, "text-xs font-bold")}>
            {userRecord?.firstName} {userRecord?.lastName}
          </Typography>
          <Typography className={clsx(classes.textGrey, " leading-none")}>
            <small>{userRecord?.userName}</small>
          </Typography>
        </div>
      </div>
      <List>
        {data.map((item, key) => (
          <Link key={key} to={item.link} onClick={item.onClick}>
            <ListItem key={item.id} disablePadding>
              <ListItemButton>
                <Typography className="text-sm">{item.text}</Typography>
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
        <Link onClick={handleLogout}>
          <ListItem disablePadding>
            <ListItemButton>
              <Typography className="text-sm">Log Out</Typography>
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          className="mt-20"
          open={open}
          onClose={toggleDrawer}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
