import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import Button from "../../components/Button";
import { useState } from "react";
import Constraints from "../../components/Constraints";
import { toast } from "react-toastify";
import axios from "../../MyAxios";
import { useNavigate } from "react-router-dom";
import { LOGIN, PERSONEL_DETAILS, USERNAME } from "../../constants/api";
import LayoutEntrance from "../../LayoutEntrance";
import BottomText from "../../components/BottomText";
import { setUser } from "../../redux/userSlice";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const Username = () => {
  const classes = useStyles();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");

  // Form submission handler
  const onSubmit = async (e) => {
    e.preventDefault();
    if (disabled) {
      toast.error("Please meet all the requirements");
    } else {
      dispatch(setUser(username));
      try {
        await axios.post(USERNAME, { userName: username });
        navigation(PERSONEL_DETAILS);
      } catch (error) {
        setError(error.response.data);
      }
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setUsername(value);
  };
  const setDisabledSU = (e) => console.log();

  return (
    <LayoutEntrance>
      <div className="mt-20">
        <Typography
          variant="h4"
          className={clsx(classes.textColor, " text-center font-extrabold")}
        >
          Sign Up for
        </Typography>
        <Typography
          variant="h4"
          className={clsx(classes.textColor, " text-center font-extrabold")}
        >
          mentee
        </Typography>
      </div>
      <form onSubmit={onSubmit} className="mt-10 flex flex-col gap-14">
        <div className="mt-3 flex flex-col">
          <label className="ml-1">
            <small>Create your username</small>
          </label>
          <input
            className={clsx(classes.border, "rounded-md p-3")}
            placeholder="Username"
            type="text"
            onChange={handleChange}
          />
          <Constraints
            string={username}
            setDisabled={setDisabled}
            setDisabledSU={setDisabledSU}
            forPass={false}
          />
        </div>

        <div className="">
          <Button>Next</Button>
        </div>
      </form>
      <BottomText
        text1={"Already have an account?"}
        text2={"Login"}
        Back={LOGIN}
      />
    </LayoutEntrance>
  );
};

export default Username;
