import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const Constraints = ({ string, setDisabledSU, setDisabled, forPass }) => {
  const [isLength, setIsLength] = useState(true);
  const [isSpaces, setIsSpaces] = useState(true);
  const [hasSpecial, setHasSpecial] = useState(true);
  const [hasUpper, setHasUpper] = useState(true);

  function containsUppercaseLetter(str) {
    return /[A-Z]/.test(str);
  }

  function hasSpaces(str) {
    return /\s/.test(str);
  }

  function hasSpecialCharacters(str) {
    // You can customize the character set based on your definition of "special characters"
    const specialCharacterRegex = /[!"'=@#$%^&*()+{}\[\]:;<>,.?~\\/-]/;
    return specialCharacterRegex.test(str);
  }

  useEffect(() => {
    if (hasSpecialCharacters(string)) {
      setHasSpecial(true);
    } else {
      setHasSpecial(false);
    }

    if (hasSpaces(string)) {
      setIsSpaces(true);
    } else {
      setIsSpaces(false);
    }

    if (string?.length < 8) {
      setIsLength(true);
    } else {
      setIsLength(false);
    }

    if (containsUppercaseLetter(string)) {
      setHasUpper(true);
    } else {
      setHasUpper(false);
    }
  }, [string]);

  useEffect(() => {
    if (isLength || isSpaces || hasSpecial) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isLength, hasSpecial, isSpaces]);

  useEffect(() => {
    if (isLength || !hasSpecial || !hasUpper) {
      setDisabledSU(true);
    } else {
      setDisabledSU(false);
    }
  }, [isLength, hasSpecial, hasUpper]);

  return (
    <div className="mt-2">
      <Typography className="text-md">
        {forPass ? "Must contain" : "Username Criteria"}
      </Typography>
      <div>
        <div className="flex items-center">
          {!isLength ? (
            <DoneIcon
              sx={{ width: "20px", color: "green", marginRight: "5px" }}
            />
          ) : (
            <ClearIcon
              sx={{ width: "20px", color: "red", marginRight: "5px" }}
              fontSize="small"
            />
          )}
          <Typography className="text-sm">8 characters or more</Typography>
        </div>
        {forPass ? (
          <div className="flex items-center">
            {hasSpecial ? (
              <DoneIcon
                sx={{ width: "20px", color: "green", marginRight: "5px" }}
              />
            ) : (
              <ClearIcon
                sx={{ width: "20px", color: "red", marginRight: "5px" }}
                fontSize="small"
              />
            )}
            <Typography className="text-sm">1 special character</Typography>
          </div>
        ) : (
          <div className="flex items-center">
            {!hasSpecial ? (
              <DoneIcon
                sx={{ width: "20px", color: "green", marginRight: "5px" }}
              />
            ) : (
              <ClearIcon
                sx={{ width: "20px", color: "red", marginRight: "5px" }}
                fontSize="small"
              />
            )}
            <Typography className="text-sm">
              Digits, letters and underscore only
            </Typography>
          </div>
        )}
        {forPass ? (
          <div className="flex items-center">
            {hasUpper ? (
              <DoneIcon
                sx={{ width: "20px", color: "green", marginRight: "5px" }}
              />
            ) : (
              <ClearIcon
                sx={{ width: "20px", color: "red", marginRight: "5px" }}
                fontSize="small"
              />
            )}
            <Typography className="text-sm">1 upper case character</Typography>
          </div>
        ) : (
          <div className="flex items-center">
            {!isSpaces ? (
              <DoneIcon
                sx={{ width: "20px", color: "green", marginRight: "5px" }}
              />
            ) : (
              <ClearIcon
                sx={{ width: "20px", color: "red", marginRight: "5px" }}
                fontSize="small"
              />
            )}
            <Typography className="text-sm">No Spaces</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Constraints;
