import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CHANGE_PASSWORD, LOGIN } from "../../constants/api";
import wave from "../../assets/img/wave.svg";
import axios from "../../MyAxios";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const EnterPassword = (res) => {
  const classes = useStyles();
  const { passwordResetUser, passwordResetOtp } = useSelector(
    (state) => state.user
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    toast.info("Please do not reload or leave page");
  }, []);

  // Form submission handler
  const onSubmit = async (data) => {
    // Do something with the form data
    data.user = passwordResetUser;
    data.code = passwordResetOtp;
    try {
      const res = await axios.post(CHANGE_PASSWORD, data);
      toast.success(res.data);
    } catch (error) {}
  };

  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h5"
            className={clsx(classes.textColor, "text-center")}
          >
            Reset Password
          </Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Password"
              type="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              })}
            />
            {errors.password && (
              <p className="text-xs italic text-red-500">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Confirm Password"
              type="password"
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === watch("password") || `Passwords do not match`,
              })}
            />
            {errors.confirmPassword && (
              <p className="text-xs italic text-red-500">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
          {res.message && (
            <p className="text-xs italic text-red-500">{res.message}</p>
          )}

          <div className="input-wrapper">
            <button
              type="submit"
              className={clsx(classes.button, "mt-3 w-full rounded-3xl p-2")}
            >
              Save
            </button>
          </div>
        </form>
        <div className="input-wrapper z-50 mt-3">
          <Typography className={clsx("text-center")}>
            Back to
            <Link to={LOGIN} className={clsx(classes.textColor)}>
              {" "}
              Login
            </Link>
          </Typography>
        </div>
        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default EnterPassword;
