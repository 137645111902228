import { useState } from "react";
import clsx from "clsx";
import { Link, Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenteeIcon from "@images/logoLight.svg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function BoxBar() {
  const [showHamburger, setShowHamburger] = useState(false);
  const { activeLink } = useSelector((state) => state.admin);

  const links = [
    { name: "Users", path: "/admin/users" },
    { name: "Community", path: "/admin/communities" },
    { name: "Groups", path: "/admin/groups" },
    { name: "Reports", path: "/admin/reports" },
    { name: "Logout", path: "/admin/logout" },
  ];
  const navigate = useNavigate();
  return (
    <Box bgcolor="primary.main" className="py-4">
      <div className="mx-auto flex flex-wrap items-center justify-between p-4">
        <a
          href="/admin"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src={MenteeIcon} className="h-4" alt="Logo" />
        </a>
        <div className="md:hidden">
          <IconButton onClick={() => setShowHamburger(!showHamburger)}>
            <MenuIcon sx={{ color: "primary.contrastText" }} />
          </IconButton>
        </div>

        <div
          className={clsx(
            "w-full md:m-auto md:block md:w-auto	",
            showHamburger ? "block" : "hidden"
          )}
        >
          <ul className="mt-4 flex flex-col rounded-lg md:mt-0 md:flex-row md:space-x-8 md:border-0 md:p-0 ">
            {links.map((link, key) => (
              <li key={key}>
                <Link
                  onClick={() => navigate(link.path)}
                  sx={{ borderColor: "primary.contrastText" }}
                  fontWeight="bold"
                  underline="none"
                  className={clsx(
                    activeLink === key && "md:border-b-4",
                    "md:hover:border-b-4",
                    "py-2 cursor-pointer"
                  )}
                  color="primary.contrastText"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Box>
  );
}
