import { CircularProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useRef, useState } from "react";
import Title from "../../components/Title";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { GROUP_CREATE_POST, GROUP_INFO, ROOT } from "../../constants/api";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import upload from "../../assets/img/upload.svg";
import useLoggedIn from "../../hooks/useLoggedIn";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 2,
    borderColor: "#887E7E",
  },
  borderGreen: {
    borderWidth: 1,
    borderColor: "#51CDA0",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const CreateGroupPost = (res) => {
  useLoggedIn();
  const classes = useStyles();
  const navigation = useNavigate();
  const { state } = useLocation();
  const { groupId } = state;
  const fileInput = useRef(null);
  const [prevFile, setPrevFile] = useState();
  const [fileName, setFileName] = useState();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Form submission handler
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      if (data.file) {
        setLoading(true);
        formData.append("file", data.file);
        formData.append("isFile", true);
      } else {
        formData.append("isFile", false);
      }
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("groupId", groupId);
      const res = await axios.post(GROUP_CREATE_POST, formData);
      toast.success(res.data);
      setFileName("");
      navigation(GROUP_INFO, { state: { id: groupId } });
    } catch (error) {}
  };

  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setValue("file", e.target.files[0]);
      setFileName(e.target.files[0].name);
      setPrevFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <Layout>
      <Title back={ROOT}>Post Stream</Title>
      <div className="m-auto flex w-5/6 flex-col gap-4">
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="flex flex-col gap-2"
        >
          <div
            className={clsx(
              classes.colorGreen,
              classes.borderGreen,
              " my-5 flex flex-col items-center justify-center rounded-md border-dashed p-14"
            )}
            onClick={() => fileInput.current.click()}
          >
            <img src={prevFile ?? upload} alt="Upload" className="mb-2" />
            <Typography>{fileName || "Upload File"}</Typography>
            <input
              className={clsx("hidden")}
              multiple={true}
              type="file"
              ref={fileInput}
              onChange={onChange}
            />
          </div>
          <div className="input-wrapper flex flex-col">
            <input
              className={clsx(classes.border, "rounded-md p-3")}
              placeholder="Title"
              type="text"
              {...register("title", {
                required: "Title is required",
              })}
            />
            {errors.title && (
              <p className="text-xs italic text-red-500">
                {errors.title.message}
              </p>
            )}
          </div>

          <div className="input-wrapper flex flex-col">
            <textarea
              className={clsx(classes.border, "h-32 rounded-md p-3")}
              placeholder="Add Description"
              {...register("description", {})}
            />
            {errors.description && (
              <p className="text-xs italic text-red-500">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className=" flex items-end justify-end">
            <button
              type="submit"
              className={clsx(classes.button, "mt-5 w-24 rounded-3xl p-2 px-6")}
            >
              {loading ? (
                <CircularProgress size={"20px"} color="inherit" />
              ) : (
                "Post"
              )}
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreateGroupPost;
