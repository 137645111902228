import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNavigator } from "@apiClient";

export default function useAdminLoggedIn() {
  const { isLoggedIn } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    setNavigator(navigate);
  }, [navigate]);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/admin/login", { replace: true });
    }
  }, [isLoggedIn, navigate]);
}
