export default function PopperList({ items }) {
  return (
    <div className="z-50 flex flex-col gap-1 rounded-lg border-b bg-slate-50 shadow-md">
      {items.map((item, key) => (
        <div
          key={key}
          onClick={item.onClick}
          className="z-50 cursor-pointer hover:bg-slate-200"
        >
          <div className="py-1 pl-2 pr-6">{item.label}</div>
        </div>
      ))}
    </div>
  );
}
