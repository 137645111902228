import { createSlice } from '@reduxjs/toolkit'

export const AdminSlice = createSlice({
  name: 'admin',
  initialState: {
    activeLink: 0,
  },
  reducers: {
    setActiveLink: (state, action) => {
      state.activeLink = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveLink } = AdminSlice.actions

export default AdminSlice.reducer
