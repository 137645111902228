import Header from './components/Header'

function Layout({ children }) {
  return (
    <div className="mx-auto flex w-full flex-col md:w-1/2">
      <Header />
      <div className="mt-14"></div>
      {children}
    </div>
  )
}

export default Layout
