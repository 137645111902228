import clsx from "clsx";
import Layout from "../../Layout";
import axios from "../../MyAxios";
import {
  GET_ALL_USERS,
  GET_USER_CHAT,
  GROUP_ADD_MEMBERS,
  GROUP_INFO,
  ROOT,
} from "../../constants/api";
import { styled, alpha } from "@mui/material/styles";
import { Avatar, Checkbox, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "@components/Title";
import InputBase from "@mui/material/InputBase";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  border: "2px solid",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: 15,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const AddMembers = () => {
  const navigation = useNavigate();
  const [users, setUsers] = useState([]);
  const classes = useStyles();
  const { state } = useLocation();
  const { groupId } = state;
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [name, setName] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const { userRecord } = useSelector((state) => state.user);
  const getUsers = async () => {
    try {
      const res = await axios.get(`${GET_ALL_USERS}/${name}`);
      console.log(res);
      setUsers(res.data.users);
    } catch (error) {}
  };
  useEffect(() => {
    getUsers();
  }, []);

  const isSelected = (id) => {
    return selectedUsers?.has(id);
  };

  const addMember = async () => {
    console.log(selectedUsers);
    try {
      const res = await axios.post(`${GROUP_ADD_MEMBERS}`, {
        groupId: groupId,
        memberIds: Array.from(selectedUsers),
      });
      toast.success("Added Successfully!");
      navigation(GROUP_INFO, { state: { id: groupId } });
    } catch (error) {}
  };

  const addToSelectedUsers = (id) => {
    setAllSelected(false);
    if (!selectedUsers?.has(id))
      setSelectedUsers(new Set(selectedUsers).add(id));
    else removeFromSelectedUsers(id);
  };

  const removeFromSelectedUsers = (id) => {
    const newSelectedUsers = new Set(selectedUsers);
    newSelectedUsers.delete(id);
    setSelectedUsers(newSelectedUsers);
  };

  const selectAll = () => {
    if (allSelected) setSelectedUsers(new Set());
    else {
      const usersSet = new Set(users?.map((user) => user.id));
      setSelectedUsers(usersSet);
    }
    setAllSelected(!allSelected);
  };

  return (
    <Layout>
      <Title back={ROOT}>Add Members</Title>
      <div className="m-4">
        <Search className="flex mb-5 justify-between items-center">
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => setName(e.target.value)}
          />
          <PersonSearchIcon
            onClick={getUsers}
            color="primary"
            className="mr-4 cursor-pointer"
          />
        </Search>

        <Typography color={"text.dark"}>Add Members</Typography>
        <div className="mt-5 flex flex-col">
          <label className={clsx(classes.textGrey)}>
            <Checkbox
              sx={{ color: "green" }}
              checked={allSelected}
              onChange={(e) => selectAll(e)}
            />
            Select All
          </label>
        </div>
        {users?.map((user) => (
          <>
            {userRecord?.id !== user?.id && (
              <label key={user?.id} className={clsx(classes.textGrey, " flex")}>
                <Checkbox
                  sx={{ color: "green" }}
                  checked={isSelected(user.id)}
                  onChange={(e) => addToSelectedUsers(user.id)}
                />
                <div className=" flex w-full flex-row items-center border-b-2 p-2">
                  <div>
                    <Avatar
                      className=" h-10 w-10 rounded-full"
                      src={user?.media?.avatarUrl}
                    />
                  </div>
                  <div className="flex w-full justify-between p-2 pl-4">
                    <div>
                      <Typography
                        className={clsx(classes.textGrey, "text-xs font-bold")}
                      >
                        {user?.firstName} {user?.lastName}
                      </Typography>
                      <Typography
                        className={clsx(classes.textGrey, " leading-none")}
                      >
                        <small>{user?.userName}</small>
                      </Typography>
                    </div>
                  </div>
                </div>
              </label>
            )}
          </>
        ))}
        <div className=" flex items-end justify-end">
          <div
            onClick={addMember}
            className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
          >
            Done
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddMembers;
