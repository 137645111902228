import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppUser from "./AppUser";
import router from "./router/routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#51CDA0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#51CDA0",
    },
    warning: {
      main: "#E2B93B",
      contrastText: "#fff",
    },
    error: {
      main: "#FB7D7D",
      contrastText: "#fff",
    },
    text: {
      dark: "#626262",
    },
    lightGreen: {
      main: "#e5f8f1",
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppUser>
          <RouterProvider router={router} />
        </AppUser>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Provider>
  );
}

export default App;
