import React from "react";
import DangerIcon from "@images/danger.svg";
import { Typography } from "@mui/material";
export default function ReportCount({ count }) {
  return (
    <div className="flex mt-1">
      <img src={DangerIcon} alt="danger" className="w-4 h-4" />
      <Typography className="ml-2 font-bold" color="error" fontSize={14}>
        Report Count: {count}
      </Typography>
    </div>
  );
}
