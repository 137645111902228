import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SideBar from "./SideBar";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Badge } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Link, useNavigate } from "react-router-dom";
import { COUNT_UNREAD_NOTIFICATIONS } from "@constants/api";
import axios from "@MyAxios";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#CACACA",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  border: "1px solid #CACACA",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.up("md")]: {
    width: "260px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: "10px",
  },
  cursor: "pointer",
}));

export default function SearchAppBar() {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const [unreadNotificationsCount, setUnreadNotificationsCount] =
    React.useState(0);

  React.useEffect(() => {
    axios.get(COUNT_UNREAD_NOTIFICATIONS).then((res) => {
      setUnreadNotificationsCount(res.data);
    });
  }, []);
  return (
    <div>
      <div className="fixed z-50 w-full bg-white md:w-1/2">
        <Toolbar className=" flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/">
              <Typography
                variant="h5"
                className={clsx(
                  classes.textColor,
                  "font-extraBold mr-4 cursor-pointer"
                )}
                component="div"
              >
                mentee
              </Typography>
            </Link>
            <Link to={"/search"} className=" cursor-pointer">
              <Search>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Link>
            <IconButton
              onClick={() => navigate("/notifications", { replace: true })}
            >
              {unreadNotificationsCount > 0 ? (
                <Badge
                  color="secondary"
                  badgeContent={unreadNotificationsCount}
                >
                  <NotificationsNoneOutlinedIcon
                    sx={{ color: "#b4b4b4", width: "40px", height: "30px" }}
                  />
                </Badge>
              ) : (
                <NotificationsNoneOutlinedIcon
                  sx={{ color: "#b4b4b4", width: "40px", height: "30px" }}
                />
              )}
            </IconButton>
          </div>
          <IconButton
            onClick={handleOpen}
            edge="start"
            aria-label="open drawer"
            sx={{ color: theme.palette.primary.main }}
          >
            <MenuIcon fontSize="large" className="ml-5" />
          </IconButton>
        </Toolbar>
        <SideBar open={open} handleClose={handleClose} />
      </div>
    </div>
  );
}
