import useAdminLoggedIn from "@hooks/useAdminLoggedIn";
import useSetActiveLink from "@hooks/useSetActiveLink";
import React, { useState } from "react";
import AdminLayout from "./AdminLayout";
import SideOptions from "@components/admin/SideOptions";
import ReportsIcon from "@images/reports.svg";
import UsersIcon from "@images/usersGreen.svg";
import GroupsIcon from "@images/groupsGreen.svg";
import StreamsIcon from "@images/streamsGreen.svg";
import CommentsIcon from "@images/commentsGreen.svg";
import MessagesIcon from "@images/messagesGreen.svg";
import PostReports from "@components/admin/Reports/PostReports";
import UserReports from "@components/admin/Reports/UserReports";
import GroupReports from "@components/admin/Reports/GroupReports";
import CommentReports from "@components/admin/Reports/CommentReports";
import MessageReports from "@components/admin/Reports/MessageReports";

const ReportTypes = Object.freeze({
  USERS: "Users",
  GROUPS: "Groups",
  STREAMS: "Streams",
  COMMENTS: "Comments",
  MESSAGES: "Messages",
});

const getReportTypeIcon = (reportType) => {
  switch (reportType) {
    case ReportTypes.STREAMS:
      return StreamsIcon;
    case ReportTypes.USERS:
      return UsersIcon;
    case ReportTypes.COMMENTS:
      return CommentsIcon;
    case ReportTypes.MESSAGES:
      return MessagesIcon;
    case ReportTypes.GROUPS:
      return GroupsIcon;
    default:
      return null;
  }
};

export default function Reports() {
  useAdminLoggedIn();
  useSetActiveLink(4);
  const [activeFilter, setActiveFilter] = useState(ReportTypes.USERS);
  const links = Object.values(ReportTypes).map((reportType) => {
    return {
      text: reportType,
      icon: <img src={getReportTypeIcon(reportType)} alt={reportType} />,
      onClick: () => {
        setActiveFilter(reportType);
      },
    };
  });

  return (
    <AdminLayout>
      <div className="h-full">
        <div className="flex h-full">
          <SideOptions
            headerText={"Reports"}
            headerIcon={<img src={ReportsIcon} alt="reports" />}
            links={links}
            activeFilter={activeFilter}
          />
          <div className="grow pl-6 pt-6">
            {activeFilter === ReportTypes.USERS && <UserReports />}
            {activeFilter === ReportTypes.GROUPS && <GroupReports />}
            {activeFilter === ReportTypes.STREAMS && <PostReports />}
            {activeFilter === ReportTypes.COMMENTS && <CommentReports />}
            {activeFilter === ReportTypes.MESSAGES && <MessageReports />}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
