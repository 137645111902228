import { useEffect, useState } from "react";

export default function useTable({ getData, pageSize, initialFilters }) {
  if (!initialFilters) initialFilters = {};
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(pageSize || 3);
  const [reload, setReload] = useState(0);
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState({});
  const [sortColumns, setSortColumns] = useState({});
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    disabledAt: 0,
    deletedAt: 0,
    ...initialFilters,
  });
  const [dateFilters, setDateFilters] = useState({});
  // reset checked state each time new data is fetched
  useEffect(() => {
    // console.log("resetting checked state");
    const obj = {};
    data.forEach((item) => {
      obj[item.id] = false;
    });
    setChecked(obj);
  }, [data]);

  // useEffect(() => {
  //   console.log('sort columns: ', sortColumns)
  // }, [sortColumns])

  // useEffect(() => {
  //   console.log("New checked states: ", checked);
  // }, [checked]);

  // useEffect(() => {
  //   console.log('New filters: ', filters)
  // }, [filters])

  // get new data
  useEffect(() => {
    setLoading(true);

    let sortOptions = [];
    for (const [key, value] of Object.entries(sortColumns)) {
      if (value) {
        sortOptions.push({ [key]: value });
      }
    }
    // console.log("query: ", query);
    // console.log("sortOptions: ", sortOptions);
    // console.log("filters: ", filters);
    // console.log("dateFilters: ", dateFilters);
    getData({
      page,
      limit: limit || 10,
      query: query.trim(),
      sortOptions,
      filters: { ...filters, ...dateFilters },
    })
      .then(({ data, count, totalPages }) => {
        setData(data);
        setCount(count);
        setTotalPages(totalPages);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [reload, page, limit, query, sortColumns, filters, dateFilters, getData]);

  return {
    setPage,
    setReload,
    reload,
    totalPages,
    count,
    page,
    query,
    setQuery,
    data,
    checked,
    setChecked,
    setLimit,
    limit,
    setSortColumns,
    sortColumns,
    loading,
    setFilters,
    setDateFilters,
  };
}
