import React, { useState } from "react";
import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import addConnection from "@images/addConnection.svg";
import message from "@images/message.svg";
import moderator from "@images/moderator.svg";
import remove from "@images/remove.svg";
import report from "@images/reportGreen.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GroupModal from "./GroupModal";
import resources from "@images/resources.svg";
import { useNavigate } from "react-router-dom";
import {
  GET_USER_CHAT,
  GROUP_ADD_MODERATOR,
  GROUP_REMOVE_MEMBER,
  GROUP_RESOURCES,
  REPORT_FORM,
} from "@constants/api";
import { toast } from "react-toastify";
import axios from "@MyAxios";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#b1b1b1",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
  active: {
    color: "#626262",
    borderBottomWidth: "4px",
    borderColor: theme.palette.primary.main,
  },
}));

function GroupDescription({ group }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const navigate = useNavigate();

  const Resources = () => {
    navigate(GROUP_RESOURCES, { state: { groupId: group.id } });
  };
  const options = [
    {
      id: 1,
      name: "Connect",
      img: addConnection,
    },
    {
      id: 2,
      name: "Message",
      img: message,
      action: () => {
        console.log(selectedUser);
        navigate(`${GET_USER_CHAT}`, {
          state: { receiverId: selectedUser?.id },
        });
      },
    },
    {
      id: 3,
      name: "Make Moderator",
      img: moderator,
      action: async () => {
        try {
          await axios.post(`${GROUP_ADD_MODERATOR}`, {
            groupId: group?.id,
            memberId: selectedUser?.id,
          });
          toast.success("Added Successfully!");
        } catch (error) {}
      },
    },
    {
      id: 4,
      name: "Remove",
      img: remove,
      action: async () => {
        try {
          await axios.post(`${GROUP_REMOVE_MEMBER}`, {
            groupId: group?.id,
            memberId: selectedUser?.id,
          });
          toast.success("Removed Successfully!");
        } catch (error) {}
      },
    },
    {
      id: 5,
      name: "Report",
      img: report,
      action: () => {
        navigate(REPORT_FORM, {
          state: { id: selectedUser?.id, report: "user" },
        });
      },
    },
  ];
  return (
    <div className="ml-5 mt-3">
      <Typography color={"text.dark"} className={clsx(" font-bold")}>
        Community
      </Typography>
      <Typography className={clsx(classes.textColor, " font-bold")}>
        {group?.community?.name}
      </Typography>
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Description
      </Typography>
      <Typography className={clsx()}>{group?.description}</Typography>
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Rules
      </Typography>
      <Typography className={clsx()}>{group?.rules}</Typography>
      <Typography color={"text.dark"} className={clsx("mt-5 mb-2 font-bold")}>
        Resources
      </Typography>
      <img
        onClick={Resources}
        className="mb-3 cursor-pointer"
        src={resources}
        alt="Resources"
      />
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Admin
      </Typography>
      <div className="flex mt-2 items-center">
        <Avatar
          src={group?.admin?.media?.avatarUrl}
          className="z-0 w-14 h-14 mr-3"
        />
        <div className="flex flex-col justify-center">
          <Typography className={clsx("font-bold")}>
            {group?.admin?.firstName} {group?.admin?.lastName}
          </Typography>
          <Typography className={clsx("")}>{group?.admin?.userName}</Typography>
        </div>
      </div>
      <Typography color={"text.dark"} className={clsx("mt-5 font-bold")}>
        Members
      </Typography>
      <div className="mb-5">
        {group?.moderators?.map((moderator) => (
          <div
            key={moderator.id}
            className="flex mt-2 items-center mr-5 justify-between"
          >
            <div className="flex">
              <Avatar
                src={moderator?.media?.avatarUrl}
                className="z-0 w-14 h-14 mr-3"
              />
              <div className="flex flex-col justify-center">
                <div className="flex items-center">
                  <div>
                    <Typography className={clsx("font-bold")}>
                      {moderator?.firstName} {moderator?.lastName}
                    </Typography>
                    <Typography className={clsx("")}>
                      {moderator?.userName}
                    </Typography>
                  </div>
                  <Typography
                    className={clsx("ml-4 p-2 rounded-lg", classes.colorGreen)}
                    color={"primary.main"}
                  >
                    Moderator
                  </Typography>
                </div>
              </div>
            </div>
            <MoreHorizIcon
              onClick={() => {
                setSelectedUser(moderator);
                setOpen(true);
              }}
              className=" cursor-pointer"
              fontSize="large"
            />
          </div>
        ))}
      </div>
      <div className="mb-5">
        {group?.members.length === 0 && <Typography>No members yet</Typography>}
        {group?.members?.map((member) => (
          <div
            key={member.id}
            className="flex mt-2 items-center mr-5 justify-between"
          >
            <div className="flex">
              <Avatar
                src={member?.media?.avatarUrl}
                className="z-0 w-14 h-14 mr-3"
              />
              <div className="flex flex-col justify-center">
                <Typography className={clsx("font-bold")}>
                  {member?.firstName} {member?.lastName}
                </Typography>
                <Typography className={clsx("")}>{member?.userName}</Typography>
              </div>
            </div>
            <MoreHorizIcon
              onClick={() => {
                setSelectedUser(member);
                setOpen(true);
              }}
              className=" cursor-pointer"
              fontSize="large"
            />
          </div>
        ))}
      </div>
      <GroupModal open={open} setOpen={setOpen} options={options} />
    </div>
  );
}

export default GroupDescription;
