import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Modal, TextField } from "@mui/material";
import axios from "../../MyAxios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import clsx from "clsx";
import {
  GET_COMMENTS,
  POST,
  POST_COMMENT,
  POST_DELETE,
  ROOT,
} from "../../constants/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import send from "../../assets/img/send.svg";
import Post from "../../components/Post";
import Comment from "../../components/Comment";
import useLoggedIn from "../../hooks/useLoggedIn";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function ViewPost() {
  useLoggedIn();
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [commentToReply, setCommentToReply] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [comment, setComment] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const navigation = useNavigate();
  const { state } = useLocation();
  const { id } = state;
  const [post, setPost] = React.useState();
  const { userRecord } = useSelector((state) => state.user);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    console.log(commentToReply);
    if (!commentToReply?.user?.userName) return;
    setComment(`@${commentToReply?.user?.userName} `);
  }, [commentToReply]);
  const options = [
    {
      id: 1,
      name: "Open",
    },
    {
      id: 2,
      name: "Connect",
    },
    {
      id: 3,
      name: "Copy Link",
    },
    {
      id: 4,
      name: "Save",
    },
    {
      id: 5,
      name: "Report",
    },
  ];

  const onComment = async (e) => {
    e.preventDefault();
    if (!comment) return;
    // COMMENT IS  A REPLY
    if (comment.trim().startsWith("@")) {
      const userNameToReply = comment.split("@")[1].split(" ")[0];
      if (userNameToReply !== commentToReply?.user?.userName) {
        // USERNAME AFTER @ IS NOT COMPLETE
        toast.error("Use complete username");
        return;
      }
      const replyTo = commentToReply?.id;
      try {
        const payload = {
          userId: userRecord.id,
          postId: post.id,
          comment,
          replyTo,
        };
        const res = await axios.post(POST_COMMENT, payload);
        console.log("response", res.data);
        const newComments = [...comments];
        const index = newComments.findIndex((c) => c.id === replyTo);
        if (!newComments[index].replies) newComments[index].replies = [];
        newComments[index].replies = [res.data, ...newComments[index].replies];
        console.log("newComments", newComments);
        setComments(newComments);
        setComment("");
        setCommentToReply(null);
      } catch (error) {}
    } else {
      try {
        // COMMENT IS A NORMAL COMMENT
        const payload = {
          userId: userRecord.id,
          postId: post.id,
          comment,
        };
        const res = await axios.post(POST_COMMENT, payload);
        console.log("response", res.data);
        setComments([res.data, ...comments]);
        setCommentToReply(null);

        setComment("");
      } catch (error) {}
    }
  };

  const getPost = async () => {
    try {
      const res = await axios.get(`${POST}/${id}`);
      setPost(res.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    getPost();
  }, []);

  React.useEffect(() => {
    const getComments = async () => {
      const res = await axios.get(`${GET_COMMENTS}/${post.id}/${page}`);
      console.log(res);
      setComments([...comments, ...res.data.comments]);
      setTotalPages(res.data.totalPages);
      console.log("total pages", res.data.totalPages);
      console.log("page", page);
    };
    if (post) {
      try {
        getComments();
      } catch (e) {
        toast.error(`Error: occurred while fetching comments`);
      }
    }
  }, [page, post]);

  const onDelete = async (post) => {
    try {
      toast.info("Deleting Post");
      await axios.post(POST_DELETE, { post }).then((res) => {
        toast.success("Post Deleted Successfully");
        navigation(ROOT);
      });
    } catch (error) {
      console.log("Error occurred", error);
      toast.error(`Error: ${error.response.data.message}`);
    }
  };

  return (
    <Layout>
      <div
        style={{ maxHeight: "80vh" }}
        className="flex flex-col justify-between"
      >
        <div className="z-50 w-full bg-white">
          <Post post={post} comment={false} />
        </div>
        <div
          style={{ borderColor: theme.palette.primary.main }}
          className="px-3 flex border-t-2 flex-col justify-between md:mx-auto"
        >
          <div
            style={{ maxHeight: post?.media?.avatarUrl ? "36vh" : "60vh" }}
            className="flex flex-col overflow-y-auto pb-14"
          >
            {comments?.map((comment) => (
              <Comment
                key={comment?.id}
                comment={comment}
                setCommentToReply={setCommentToReply}
              />
            ))}
          </div>
          <div
            onClick={() => {
              setPage(page + 1);
            }}
            className={clsx(
              totalPages <= page && "hidden",
              "grow text-center text-sm font-normal"
            )}
          >
            Show More
          </div>
        </div>
        <div
          style={{ borderColor: theme.palette.primary.main }}
          className="fixed border-2 border-t-0 bottom-0  flex w-full items-center bg-white justify-center md:w-6/12"
        >
          <form onSubmit={onComment} className="flex items-center p-4">
            <Avatar src={userRecord?.media?.avatarUrl} />
            <TextField
              type="text"
              size="small"
              id="outlined-basic"
              fullWidth
              value={comment}
              placeholder="Add Comment"
              InputProps={{
                sx: {
                  borderRadius: 10,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                },
              }}
              className="mx-2 w-72"
              onChange={(e) => setComment(e.target.value)}
            />
            <button type="submit">
              <img src={send} alt="Send" />
            </button>
          </form>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {options.map((option) => (
              <Link
                to={option.name}
                key={option.id}
                className="block py-2 text-sm"
              >
                {option.name}
              </Link>
            ))}
            {post?.user?.id === userRecord?.id && (
              <Typography
                onClick={() => onDelete(post)}
                className="block py-2 text-sm"
              >
                Delete
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    </Layout>
  );
}
