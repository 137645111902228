import { Button, Modal } from "@mui/material";
import { ModalBox } from "@components/admin/StyledComponents";
import MyAvatar from "../MyAvatar";
import { formatDate } from "@helpers";
import DetailRow from "../DetailRow";
import { useEffect, useState } from "react";
import ReportCount from "./ReportCount";
import PostMedia from "./PostMedia";

export default function PostReportDetail({
  setPostReport,
  postReport,
  actionButtons,
}) {
  const [open, setOpen] = useState(false);
  const user = postReport?.postReported?.user;
  const reporter = postReport?.reportedBy;
  useEffect(() => {
    if (postReport) setOpen(true);
  }, [postReport]);
  if (!postReport) return <div></div>;
  return (
    <Modal
      open={open}
      onClose={() => {
        setPostReport(null);
        setOpen(false);
      }}
    >
      <ModalBox sx={{ p: 4 }} className="rounded-lg shadow-md overflow-auto">
        <div className="flex gap-6">
          <MyAvatar
            src={user?.media?.avatarUrl}
            name={user?.firstName + " " + user?.lastName}
            subtext={`@${user?.userName}`}
            textProps={{ className: "font-bold text-gray-600" }}
          />
          <ReportCount count={postReport?.reportCount} />
        </div>
        <div>
          <div className="flex">
            <table className="w-full text-left text-sm mt-5">
              <tbody>
                <DetailRow label="Email:" value={user?.emailAddress} />
                <DetailRow
                  label="Provider:"
                  value={user?.provider || "Email"}
                />
                <DetailRow
                  label="Registration Date:"
                  value={formatDate(user?.createdAt)}
                />
                <DetailRow
                  label="Reporter:"
                  value={reporter?.firstName + " " + reporter?.lastName}
                />
                <DetailRow
                  label="Reporter Username:"
                  value={reporter?.userName}
                />
                <DetailRow
                  label="Reported On:"
                  value={formatDate(postReport?.createdAt)}
                />
                <DetailRow
                  label="Report Description:"
                  value={postReport?.reason}
                />
              </tbody>
            </table>
            <div className="ml-28 mt-8">
              <PostMedia post={postReport.postReported} comment={[]} />
            </div>
          </div>
          <div className="flex gap-2 mt-4 justify-end">
            {actionButtons?.map((button, index) => (
              <Button variant="contained" key={index} {...button} />
            ))}
          </div>
        </div>
      </ModalBox>
    </Modal>
  );
}
