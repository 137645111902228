import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import clsx from "clsx";

export default function PostMedia({ post, comment }) {
  return (
    <div className="">
      <Card className={clsx(" z-0 mx-auto mb-5 shadow-none")}>
        <Typography
          variant="body2"
          className="ml-2 font-bold"
          color="text.secondary"
        >
          {post?.title}
        </Typography>

        <Typography variant="body2" className="ml-2" color="text.secondary">
          {post?.description}
        </Typography>
        {post?.media?.avatarUrl && (
          <CardMedia
            component="img"
            className="mx-auto mt-2 max-w-80 rounded-2xl object-contain"
            image={post?.media?.avatarUrl}
            alt="Stream Media"
          />
        )}
      </Card>
    </div>
  );
}
