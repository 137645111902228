import { useState } from "react";
import DisabledIcon from "@images/disabled.svg";
import DeletedIcon from "@images/deleted.svg";
import AllIcon from "@images/all.svg";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import ConfirmationModal from "@components/admin/ConfirmationModal";

export const ActionFilters = Object.freeze({
  ALL: "Active",
  DISABLED: "Disabled",
  DELETED: "Deleted",
});
export default function useActionFilters({
  apiDelete,
  apiDisable,
  apiReinstate,
  setFilters,
  onLinkClick,
  setReload,
  getSelectedItems,
  customActions,
}) {
  // Optional Params
  customActions = customActions || [];
  onLinkClick = onLinkClick || (() => {});
  // STATE
  const [activeFilter, setActiveFilter] = useState(ActionFilters.ALL);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openDisableConfirmation, setOpenDisableConfirmation] = useState(false);
  const [openReinstateConfirmation, setOpenReinstateConfirmation] =
    useState(false);

  // ACTION HANDLERS
  const deleteItems = (ids) => {
    setOpenDeleteConfirmation(false);
    apiDelete({ ids })
      .then((deleteUsers) => {
        toast.success(`${deleteUsers.length} Item(s) Deleted Successfully`);
        setReload((reload) => reload + 1);
      })
      .catch(() => {});
  };
  const disableItems = (ids) => {
    setOpenDisableConfirmation(false);
    apiDisable({ ids })
      .then((disabledUsers) => {
        toast.success(`${disabledUsers.length} Item(s) Disabled Successfully`);
        setReload((reload) => reload + 1);
      })
      .catch(() => {});
  };
  const reinstateItems = (ids) => {
    setOpenReinstateConfirmation(false);
    apiReinstate({ ids })
      .then((reinstatedUsers) => {
        toast.success(
          `${reinstatedUsers.length} Item(s) Reinstated Successfully`
        );
        setReload((reload) => reload + 1);
      })
      .catch(() => {});
  };

  // ACTION BUTTONS FOR BATCH OPERATIONS
  const getActionButtons = () => {
    return (
      <div className="flex gap-3">
        {[ActionFilters.ALL].includes(activeFilter) && (
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setOpenDisableConfirmation(true);
            }}
          >
            Disable
          </Button>
        )}
        {[ActionFilters.ALL, ActionFilters.DISABLED].includes(activeFilter) && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenDeleteConfirmation(true);
            }}
          >
            Delete
          </Button>
        )}

        {[ActionFilters.DELETED, ActionFilters.DISABLED].includes(
          activeFilter
        ) && (
          <Button
            variant="contained"
            onClick={() => {
              setOpenReinstateConfirmation(true);
            }}
          >
            Reinstate
          </Button>
        )}
      </div>
    );
  };

  // INDIVIDUAL ACTIONS
  const getActions = (item) => {
    const deleteAction = {
      label: "Delete",
      onClick: () => deleteItems([item.id]),
    };
    const disableAction = {
      label: "Disable",
      onClick: () => disableItems([item.id]),
    };
    const reinstateAction = {
      label: "Reinstate",
      onClick: () => reinstateItems([item.id]),
    };
    let actions = [];
    if (activeFilter === ActionFilters.ALL) {
      actions = [deleteAction, disableAction];
    } else if (activeFilter === ActionFilters.DISABLED) {
      actions = [reinstateAction];
    } else if (activeFilter === ActionFilters.DELETED) {
      actions = [reinstateAction];
    }
    customActions = customActions.map((action) => {
      return {
        ...action,
        onClick: action.generateClickHandler(item),
      };
    });
    return [...actions, ...customActions];
  };

  // SIDE OPTIONS LINKS
  const getActionLinks = () => {
    return [
      {
        text: ActionFilters.ALL,
        onClick: () => {
          onLinkClick();
          setActiveFilter(ActionFilters.ALL);
          setFilters({ disabledAt: 0, deletedAt: 0 });
        },
        icon: <img src={AllIcon} alt="all" />,
      },
      {
        text: ActionFilters.DISABLED,
        onClick: () => {
          onLinkClick();
          setActiveFilter(ActionFilters.DISABLED);
          setFilters({ disabledAt: { ">": 0 } });
        },
        icon: <img src={DisabledIcon} alt="disabled" />,
      },
      {
        text: ActionFilters.DELETED,
        onClick: () => {
          onLinkClick();
          setActiveFilter(ActionFilters.DELETED);
          setFilters({ deletedAt: { ">": 0 } });
        },
        icon: <img src={DeletedIcon} alt="deleted" />,
      },
    ];
  };

  // CONFIRMATION MODALS
  const getConfirmationModals = () => {
    return (
      <div>
        <ConfirmationModal
          open={openReinstateConfirmation}
          setOpen={setOpenReinstateConfirmation}
          buttons={[
            {
              variant: "contained",
              onClick: () => reinstateItems(getSelectedItems()),
              children: "Reinstate",
            },
          ]}
        >
          Are you sure you want to reinstate selected rows?
        </ConfirmationModal>

        <ConfirmationModal
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          buttons={[
            {
              variant: "contained",
              onClick: () => deleteItems(getSelectedItems()),
              children: "Delete",
              color: "error",
            },
          ]}
        >
          Are you sure you want to delete selected rows?{" "}
        </ConfirmationModal>
        <ConfirmationModal
          open={openDisableConfirmation}
          setOpen={setOpenDisableConfirmation}
          buttons={[
            {
              variant: "contained",
              onClick: () => disableItems(getSelectedItems()),
              children: "Disable",
              color: "warning",
            },
          ]}
        >
          Are you sure you want to disable selected rows?
        </ConfirmationModal>
      </div>
    );
  };
  return {
    activeFilter,
    setActiveFilter,
    getActions,
    getActionLinks,
    getActionButtons,
    getConfirmationModals,
  };
}
