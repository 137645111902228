import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPasswordResetOtp } from "../../redux/userSlice";
import { ENTER_PASSWORD, LOGIN, VERIFY_OTP } from "../../constants/api";
import { Link, useNavigate } from "react-router-dom";
import wave from "../../assets/img/wave.svg";
import axios from "../../MyAxios";

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: 1,
    borderColor: "#887E7E",
  },
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  fontSmall: {
    fontSize: "10px",
  },
  bottomImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

const EnterOTP = (res) => {
  const numberOfDigits = 4;
  const classes = useStyles();
  const { passwordResetUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  // Form submission handler
  const onSubmit = async (e) => {
    // Do something with the form data
    const code = Number(otp.join(""));
    e.preventDefault();
    const data = {
      user: passwordResetUser,
      code: code,
    };
    console.log(data);
    dispatch(setPasswordResetOtp(data.code));
    try {
      const res = await axios.post(VERIFY_OTP, data);
      toast.success(res.data);
      navigate(ENTER_PASSWORD);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="m-auto flex w-5/6 flex-col gap-4 md:w-3/12">
        <div className="mt-20">
          <Typography
            variant="h4"
            className={clsx(
              classes.textColor,
              "mt-3 text-center font-extrabold"
            )}
          >
            One-Time
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.textColor, "text-center font-extrabold")}
          >
            Code
          </Typography>
          <Typography
            variant="h4"
            className={clsx(classes.textColor, " text-center font-extrabold")}
          >
            Verification
          </Typography>
        </div>
        <Typography className={clsx("mt-5 text-center")}>
          <small>
            Please enter the code we sent to{" "}
            <span className={classes.textColor}>
              {res?.user?.emailAddress}
              {/* azeem10011@gmail.com */}
            </span>
          </small>
        </Typography>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          <div className="flex items-center justify-center gap-4">
            {otp.map((digit, index) => (
              <input
                key={index}
                value={digit}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                ref={(reference) =>
                  (otpBoxReference.current[index] = reference)
                }
                className={`mx-1 block h-14 w-14 appearance-none rounded-md border p-3 shadow-md focus:outline-none`}
              />
            ))}
          </div>
          {res.message && (
            <p className="text-xs italic text-red-500">{res.message}</p>
          )}

          <div className="input-wrapper">
            <button
              type="submit"
              className={clsx(classes.button, "mt-3 w-full rounded-3xl p-2")}
            >
              Next
            </button>
          </div>
        </form>
        <div className="input-wrapper mt-3">
          <Typography className={clsx("z-50 text-center")}>
            Back to
            <Link to={LOGIN} className={clsx(classes.textColor)}>
              {" "}
              Login
            </Link>
          </Typography>
        </div>
        <div className={clsx(classes.bottomImg, "block sm:hidden")}>
          <img src={wave} alt="Wave" className="z-0 w-full" />
        </div>
      </div>
    </div>
  );
};

export default EnterOTP;
