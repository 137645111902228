import { createSlice } from "@reduxjs/toolkit";

export const PostSlice = createSlice({
  name: "title",
  initialState: {
    title: "",
    description: "",
    media: "",
    communities: [],
    connections: [],
    selectedGroups: [],
    fileName: "",
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setMedia: (state, action) => {
      state.media = action.payload;
    },

    addToGroups: (state, action) => {
      if (state.selectedGroups.includes(action.payload)) {
        state.selectedGroups.splice(
          state.selectedGroups.indexOf(action.payload),
          1
        );
      } else {
        state.selectedGroups.push(action.payload);
      }
    },
    addToCommunities: (state, action) => {
      if (state.communities.includes(action.payload)) {
        state.communities.splice(state.communities.indexOf(action.payload), 1);
      } else {
        state.communities.push(action.payload);
      }
    },
    addToConnections: (state, action) => {
      if (state.connections.includes(action.payload)) {
        state.connections.splice(state.connections.indexOf(action.payload), 1);
      } else {
        state.connections.push(action.payload);
      }
    },

    addAllToGroups: (state, action) => {
      const newSet = action.payload;
      state.selectedGroups = newSet;
    },
    addAllToCommunities: (state, action) => {
      const newSet = action.payload;
      state.communities = newSet;
    },
    addAllToConnections: (state, action) => {
      const newSet = action.payload;
      state.connections = newSet;
    },
    clearGroups: (state, action) => {
      state.selectedGroups = [];
    },
    clearCommunities: (state, action) => {
      state.communities = [];
    },
    clearConnections: (state, action) => {
      state.connections = [];
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    resetPost: (state, action) => {
      state.title = "";
      state.description = "";
      state.media = "";
      state.communities = [];
      state.connections = [];
      state.fileName = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTitle,
  setDescription,
  setMedia,
  setFileName,
  addToCommunities,
  addToConnections,
  clearCommunities,
  clearConnections,
  clearGroups,
  addAllToCommunities,
  addAllToConnections,
  resetPost,
  addAllToGroups,
  addToGroups,
} = PostSlice.actions;

export default PostSlice.reducer;
