import api from "@apiClient";
import ReportWarning from "@components/admin/ReportDetails/ReportWarning";
import { getSelectedItems } from "@helpers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function useReportActions({
  hideModal,
  reloadPage,
  getUserFromReport,
  selectedReport,
  getPostFromReport,
  getGroupFromReport,
  getCommentFromReport,
  checked,
  data,
}) {
  const [selectedWarningReport, setSelectedWarningReport] = useState(null);

  const deleteItems = ({ ids, apiDelete }) => {
    apiDelete({ ids })
      .then((deleteUsers) => {
        hideModal();
        toast.success(`${deleteUsers.length} Item(s) Deleted Successfully`);
        reloadPage();
      })
      .catch(() => {});
  };
  const disableItems = ({ ids, apiDisable }) => {
    apiDisable({ ids })
      .then((disabledUsers) => {
        hideModal();
        toast.success(`${disabledUsers.length} Item(s) Disabled Successfully`);
        reloadPage();
      })
      .catch(() => {});
  };

  const resolveReports = (ids) => {
    api
      .resolveReports({ ids })
      .then((resolvedReports) => {
        hideModal();

        toast.success(
          `${resolvedReports.length} Report(s) Resolved Successfully`
        );
        reloadPage();
      })
      .catch(() => {});
  };

  const deleteUsers = (ids) => {
    deleteItems({ ids, apiDelete: api.deleteUsers });
  };
  const disableUsers = (ids) => {
    disableItems({ ids, apiDisable: api.disableUsers });
  };

  const deletePosts = (ids) => {
    deleteItems({ ids, apiDelete: api.deletePosts });
  };

  const deleteGroups = (ids) => {
    deleteItems({ ids, apiDelete: api.deleteGroups });
  };

  const disableGroups = (ids) => {
    disableItems({ ids, apiDisable: api.disableGroups });
  };

  const deleteComments = (ids) => {
    deleteItems({ ids, apiDelete: api.deleteComments });
  };
  const [actionButtons, setActionButtons] = useState([]);

  useEffect(() => {
    const actionButtons = [
      {
        children: "Resolve",
        onClick: () => resolveReports([selectedReport?.id]),
      },
    ];
    if (getUserFromReport) {
      actionButtons.unshift(
        {
          children: "Delete User",
          onClick: () => deleteUsers([getUserFromReport(selectedReport)?.id]),
          color: "error",
        },
        {
          children: "Disable User",
          onClick: () => disableUsers([getUserFromReport(selectedReport)?.id]),
          color: "warning",
        },
        {
          children: "Warn User",
          onClick: () => {
            setSelectedWarningReport(selectedReport);
            hideModal();
          },
          color: "warning",
        }
      );
    }
    if (getPostFromReport) {
      actionButtons.unshift({
        children: "Remove Post",
        onClick: () => deletePosts([getPostFromReport(selectedReport)?.id]),
        color: "error",
      });
    }

    if (getGroupFromReport) {
      actionButtons.unshift({
        children: "Delete Group",
        onClick: () => deleteGroups([getGroupFromReport(selectedReport)?.id]),
        color: "error",
      });
      actionButtons.unshift({
        children: "Disable Group",
        onClick: () => disableGroups([getGroupFromReport(selectedReport)?.id]),
        color: "warning",
      });
    }
    if (getCommentFromReport) {
      actionButtons.unshift({
        children: "Delete Comment",
        onClick: () =>
          deleteComments([getCommentFromReport(selectedReport)?.id]),
        color: "error",
      });
    }

    setActionButtons(actionButtons);
  }, [selectedReport]);

  const selectedActionButtons = [
    {
      children: "Resolve",
      onClick: () => resolveReports(getSelectedItems(checked)),
    },
  ];
  if (getUserFromReport) {
    selectedActionButtons.unshift(
      {
        children: "Delete User",
        onClick: () =>
          deleteUsers(
            getSelectedItems(checked).map(
              (reporId) =>
                getUserFromReport(data.find((report) => report.id === reporId))
                  .id
            )
          ),
        color: "error",
      },
      {
        children: "Disable User",
        onClick: () =>
          disableUsers(
            getSelectedItems(checked).map(
              (reporId) =>
                getUserFromReport(data.find((report) => report.id === reporId))
                  .id
            )
          ),
        color: "warning",
      }
    );
  }
  if (getPostFromReport) {
    selectedActionButtons.unshift({
      children: "Remove Post",
      onClick: () =>
        deletePosts(
          getSelectedItems(checked).map(
            (reporId) =>
              getPostFromReport(data.find((report) => report.id === reporId)).id
          )
        ),
      color: "error",
    });
  }
  if (getGroupFromReport) {
    selectedActionButtons.unshift({
      children: "Delete Group",
      onClick: () =>
        deleteGroups(
          getSelectedItems(checked).map(
            (reporId) =>
              getGroupFromReport(data.find((report) => report.id === reporId))
                .id
          )
        ),
      color: "error",
    });

    selectedActionButtons.unshift({
      children: "Disable Group",
      onClick: () =>
        disableGroups(
          getSelectedItems(checked).map(
            (reporId) =>
              getGroupFromReport(data.find((report) => report.id === reporId))
                .id
          )
        ),
      color: "warning",
    });
  }

  if (getCommentFromReport) {
    selectedActionButtons.unshift({
      children: "Delete Comment",
      onClick: () =>
        deleteComments(
          getSelectedItems(checked).map(
            (reporId) =>
              getCommentFromReport(data.find((report) => report.id === reporId))
                .id
          )
        ),
      color: "error",
    });
  }

  const getActionLinks = (item) => {
    const links = [
      {
        label: "Mark as Resolved",
        onClick: () => resolveReports([item.id]),
      },
    ];

    if (getUserFromReport) {
      links.unshift(
        {
          label: "Delete User",
          onClick: () => deleteUsers([getUserFromReport(item).id]), // delete user
        },
        {
          label: "Disable User",
          onClick: () => disableUsers([getUserFromReport(item).id]), // disable user
        },
        {
          label: "Warn User",
          onClick: () => {
            setSelectedWarningReport(item);
            hideModal();
          },
        }
      );
    }
    if (getPostFromReport) {
      links.unshift({
        label: "Remove Post",
        onClick: () => deletePosts([getPostFromReport(item).id]), // disable user
      });
    }
    if (getGroupFromReport) {
      links.unshift({
        label: "Delete Group",
        onClick: () => deleteGroups([getGroupFromReport(item).id]), // disable user
      });
      links.unshift({
        label: "Disable Group",
        onClick: () => disableGroups([getGroupFromReport(item).id]), // disable user
      });
    }

    if (getCommentFromReport) {
      links.unshift({
        label: "Delete Comment",
        onClick: () => deleteComments([getCommentFromReport(item).id]), // disable user
      });
    }
    return links;
  };

  const warningModal = () => {
    if (!selectedWarningReport) return <></>;
    return (
      <ReportWarning
        report={selectedWarningReport}
        setReport={setSelectedWarningReport}
        user={getUserFromReport(selectedWarningReport)}
      />
    );
  };
  return {
    actionButtons,
    selectedActionButtons,
    getActionLinks,
    warningModal,
  };
}
