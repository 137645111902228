import React, { useEffect } from "react";
import { setIsLoggedIn } from "@redux/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("token");
    dispatch(setIsLoggedIn(false));
    navigate("/admin/login", { replace: true });
  }, [navigate, dispatch]);

  return <CircularProgress />;
}
