import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  activeLink: {
    color: theme.palette.primary.main,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  darkText: {
    color: theme.palette.text.dark,
  },
}))

function Link({ text, icon, onClick, active }) {
  const classes = useStyles()
  return (
    <div>
      <div
        onClick={onClick}
        className={clsx(
          active && classes.activeLink,
          active && 'bg-slate-200',
          'flex cursor-pointer items-center px-6 py-2 hover:bg-slate-200'
        )}
      >
        <div className="mr-4">{icon}</div>
        <div className="text-base">{text}</div>
      </div>
    </div>
  )
}

export default function SideOptions({
  headerText,
  headerIcon,
  links,
  activeFilter,
}) {
  const classes = useStyles()

  return (
    <div className="flex h-full flex-col border-r-2 pt-6">
      <div className="flex items-center px-6 pt-1">
        {headerIcon && <div className="mr-4">{headerIcon}</div>}
        <div className={clsx(classes.darkText && 'text-xl font-medium	')}>
          {headerText}
        </div>
      </div>
      <div className="my-auto">
        {links.map((link, key) => (
          <Link
            key={key}
            text={link.text}
            icon={link.icon}
            onClick={link.onClick}
            active={activeFilter === link.text}
          />
        ))}
      </div>
    </div>
  )
}
