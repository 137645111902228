import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Title from "../../components/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  addAllToConnections,
  addToConnections,
  clearConnections,
} from "../../redux/postSlice";
import Layout from "../../Layout";
import { ADD_COMMUNITY_CONNECTION, GET_CONNECTIONS } from "../../constants/api";
import { Link } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";
import axios from "@MyAxios";
import MyAvatar from "@components/admin/MyAvatar";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  bgGrey: {
    backgroundColor: "#efefef",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function SelectConn() {
  useLoggedIn();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkedConn, setCheckedConn] = useState(false);
  const [users, setUsers] = useState([]);
  const { connections } = useSelector((state) => state.post);

  useEffect(() => {
    console.log(connections);
  }, [connections]);
  const isSelected = (id) => {
    return connections.includes(id);
  };
  useEffect(() => {
    axios
      .get(GET_CONNECTIONS)
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {});
  }, []);

  const selectAll = (e) => {
    if (e.target.checked) {
      dispatch(addAllToConnections(users.map((user) => user.id)));
    } else {
      dispatch(clearConnections([]));
    }
    setCheckedConn(e.target.checked);
  };
  return (
    <Layout>
      <Title back={ADD_COMMUNITY_CONNECTION}>Select Connection</Title>
      <div className="m-5 mt-10 flex flex-col">
        <label className={clsx(classes.textGrey, "mx-3 mb-3")}>
          <Checkbox
            sx={{ color: "green" }}
            checked={checkedConn}
            onChange={(e) => selectAll(e)}
          />
          Select All
        </label>
        {users.map((user, key) => (
          <label key={key} className={clsx(classes.textGrey, "mx-3 flex")}>
            <Checkbox
              sx={{ color: "green" }}
              checked={isSelected(user.id)}
              onChange={(e) => dispatch(addToConnections(user.id))}
            />
            <MyAvatar
              name={user?.firstName + " " + user?.lastName}
              src={user?.media?.avatarUrl}
            />
          </label>
        ))}
      </div>
      <div className=" flex items-end justify-end">
        <Link
          to={ADD_COMMUNITY_CONNECTION}
          className={clsx(classes.button, "mr-10 mt-5 rounded-3xl p-2 px-6")}
        >
          Done
        </Link>
      </div>
    </Layout>
  );
}

export default SelectConn;
