import PopperWrapper from "./PopperWrapper";
import { IconButton, Typography } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import PopperList from "./PopperList";

export default function ReportFilter({
  activeReportFilter,
  count,
  reportFilterButtons,
}) {
  return (
    <div className="flex items-center gap-1">
      <Typography fontWeight="bold">{activeReportFilter}</Typography>
      <Typography fontWeight="bold">({count})</Typography>
      <PopperWrapper id="date-filter">
        <IconButton>
          <ArrowDropDown />
        </IconButton>

        <PopperList items={reportFilterButtons} />
      </PopperWrapper>
    </div>
  );
}
