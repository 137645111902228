import { ActionFilters } from "@hooks/useActionFilters";
import dayjs from "dayjs";

export const DateOptions = Object.freeze({
  ANY: "Any",
  TODAY: "Today",
  THIS_WEEK: "This Week",
  THIS_MONTH: "This Month",
});

export const ReportFilters = Object.freeze({
  RESOLVED: "Resolved",
  UNRESOLVED: "Unresolved",
});
export function getLastDate(filter) {
  let currentDate = new Date();

  if (filter === DateOptions.TODAY) {
    return currentDate.setHours(0, 0, 0, 0).valueOf();
  } else if (filter === DateOptions.THIS_WEEK) {
    // Set the date to the last day of the current week
    // currentDate.setDate(currentDate.getDate() - (7 - currentDate.getDay()));
    // currentDate.setHours(23, 59, 59, 999);

    return new Date(currentDate.valueOf() - 7 * 24 * 60 * 60 * 1000).valueOf();
  } else if (filter === DateOptions.THIS_MONTH) {
    // currentDate.setMonth(currentDate.getMonth() - 1, 1);
    // currentDate.setHours(23, 59, 59, 999);
    return new Date(currentDate.valueOf() - 30 * 24 * 60 * 60 * 1000).valueOf();
  }
}

export function getSelectedItems(items) {
  return Object.keys(items).filter((key) => items[key]);
}

export const calTime = (timestamp) => {
  const day1 = dayjs(timestamp);
  const day2 = dayjs();
  let time = 0;

  time = day2.diff(day1, "s");
  if (time < 60) {
    return `${time} seconds ago`;
  }

  time = day2.diff(day1, "m");
  if (time < 60) {
    return `${time} minutes ago`;
  }

  time = day2.diff(day1, "h");
  if (time < 24) {
    return `${time} hours ago`;
  }

  time = day2.diff(day1, "d");
  if (time < 31) {
    return `${time} days ago`;
  }

  time = day2.diff(day1, "M"); // Use 'M' for months
  if (time < 12) {
    return `${time} months ago`;
  }

  time = day2.diff(day1, "y");
  return `${time} years ago`;
};
export function getModelStatus(model) {
  if (model.disabledAt > 0) {
    return "Disabled";
  } else if (model.deletedAt > 0) {
    return "Deleted";
  } else {
    return "Active";
  }
}

export function getModelActionDate(model) {
  if (model.disabledAt > 0) {
    return formatDate(model.disabledAt);
  } else if (model.deletedAt > 0) {
    return formatDate(model.deletedAt);
  } else {
    return null;
  }
}

export function formatDate(date) {
  return new Date(date).toLocaleDateString("en-GB");
}

export function createDateColumn(actionFilter) {
  switch (actionFilter) {
    case ActionFilters.DELETED:
      return {
        columnName: "Deleted At",
        field: "deletedAt",
        render: (item) => new Date(item.deletedAt).toLocaleDateString("en-GB"),
      };
    case ActionFilters.DISABLED:
      return {
        columnName: "Disabled At",
        field: "disabledAt",
        render: (item) => new Date(item.disabledAt).toLocaleDateString("en-GB"),
      };
    default:
      return {
        columnName: "Created At",
        field: "createdAt",
        render: (item) => new Date(item.createdAt).toLocaleDateString("en-GB"),
      };
  }
}
