import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";
import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatBox from "../../components/ChatBox";
import ChatOptions from "../../components/ChatOptions";
import axios from "../../MyAxios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import back from "../../assets/img/back.svg";
import send from "../../assets/img/send.svg";
import cameraChat from "../../assets/img/cameraChat.svg";
import { GET_MESSAGES, SEND_MESSAGE } from "../../constants/api";
import Layout from "../../Layout";
import { useLocation, useNavigate } from "react-router-dom";
import useLoggedIn from "../../hooks/useLoggedIn";

const URL = process.env.REACT_APP_URL ?? "http://localhost:1337";
var io = sailsIOClient(socketIOClient);
io.sails.url = URL;
// io.sails.connect(URL);

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

const Messages = ({ messages, userRecord, height, showMore, page }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    if (page === 1) scrollToBottom();
  }, [userRecord, messages]);

  return (
    <div
      className=" flex flex-col overflow-y-auto px-5 py-3"
      style={{ height: height - 200 }}
    >
      <Typography
        onClick={showMore}
        className=" cursor-pointer text-center underline"
      >
        Show more
      </Typography>
      {messages.map((msg, index) => (
        <div
          key={msg?.id}
          className={clsx(
            userRecord?.id === msg?.user?.id ? "flex-row-reverse" : "flex-row",
            "flex items-center"
          )}
        >
          <ChatBox
            key={index}
            sender={userRecord?.id === msg?.user?.id ? true : false}
            img={msg?.media?.avatarUrl}
          >
            {msg.message}
          </ChatBox>
          <ChatOptions msg={msg} />
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

function UserChat() {
  useLoggedIn();
  const classes = useStyles();
  const { state } = useLocation();
  const { receiverId } = state;
  const theme = useTheme();
  const navigation = useNavigate();
  const fileInput = useRef(null);
  const [messagesArr, setMessagesArr] = useState([]);
  const { userRecord } = useSelector((state) => state.user);
  const [roomId, setRoomId] = useState();
  const [receiverRec, setReceiverRec] = useState();
  const [height, setHeight] = useState(window.innerHeight);
  const [message, setMessage] = useState("");
  const [deletedMsg, setDeletedMsgId] = useState("");
  const [page, setPage] = useState(1);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const sendMessage = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("message", message);
    formData.append("roomId", roomId);
    formData.append("file", file);
    try {
      await axios.post(SEND_MESSAGE, formData).then((res) => {
        console.log(res);
        setLoading(false);
        setMessage("");
        setFile(null);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getRoomId = async () => {
      try {
        const res = await axios.get(`/user/chat/${receiverId}`);
        setReceiverRec(res.data.receiverRec);
        setRoomId(res.data.roomId);
      } catch (error) {}
    };
    getRoomId();
  }, []);

  useEffect(() => {
    if (deletedMsg) {
      const updatedMessages = messagesArr.filter(
        (message) => message?.id !== deletedMsg
      );
      setMessagesArr(updatedMessages);
      console.log(`Message with ID ${deletedMsg} deleted successfully.`);
    }
  }, [deletedMsg]);

  const showMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (receiverId) {
      try {
        axios.get(`${GET_MESSAGES}/${receiverId}/${page}`).then((res) => {
          setMessagesArr((prevMessages) => [
            ...res.data.messages,
            ...prevMessages,
          ]);
        });
      } catch (error) {}
    }
  }, [page, receiverId]);

  useEffect(() => {
    if (roomId) {
      console.log("Connecting");
      if (!io.socket.isConnected()) io.socket.reconnect();
      io.socket.get(
        "/subscribe",
        { roomId },
        function serverResponded(body, JWR) {
          io.socket.on("message", function ({ message, user, media }) {
            console.log(message);
            setMessagesArr((prevMessages) => [
              ...prevMessages,
              { message, user, media },
            ]);
          });
          io.socket.on("delete-message", function ({ messageId }) {
            console.log(messageId);
            setDeletedMsgId(messageId);
          });
        }
      );
    }
  }, [roomId]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);

  const onChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <Layout>
      <div
        className={clsx(
          classes.colorGreen,
          "shadow-black-100 flex justify-between py-2 shadow-md"
        )}
      >
        <div className=" ml-5 flex  items-center">
          <img
            onClick={() => navigation(-1)}
            className="h-5 w-5"
            src={back}
            alt="Back"
          />
          <Avatar
            className=" ml-3 h-14 w-14 rounded-full"
            src={receiverRec?.media?.avatarUrl}
          />
          <div className=" ml-4">
            <Typography className={clsx(" leading-3")}>
              {receiverRec?.firstName} {receiverRec?.lastName}
            </Typography>
            <Typography className={clsx(classes.textGrey)}>
              <small>{receiverRec?.userName}</small>
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <Messages
          messages={messagesArr}
          userRecord={userRecord}
          height={height}
          page={page}
          showMore={showMore}
        />
        <div className="fixed bottom-0 flex w-full items-center justify-center md:w-6/12">
          <form onSubmit={sendMessage} className="flex items-center p-4">
            <input
              className={clsx("hidden")}
              multiple={true}
              type="file"
              ref={fileInput}
              onChange={(e) => onChange(e)}
            />
            <img
              src={cameraChat}
              alt="Camera Chat"
              onClick={() => fileInput.current.click()}
            />
            <TextField
              type="text"
              size="small"
              id="outlined-basic"
              fullWidth
              value={message}
              placeholder="Message"
              InputProps={{
                sx: {
                  borderRadius: 10,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                },
              }}
              className="mx-2 w-72"
              onChange={(e) => setMessage(e.target.value)}
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <button type="submit">
                <img src={send} alt="Send" />
              </button>
            )}
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default UserChat;
