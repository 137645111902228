import axios from "@MyAxios";
import { GET_BLOCKED_USERS, UNBLOCK_USER } from "@constants/api";
import React, { useEffect, useState } from "react";
import MyAvatar from "./admin/MyAvatar";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

export default function BlockedUsers() {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [reload, setReload] = useState(0);

  useEffect(() => {
    axios
      .get(GET_BLOCKED_USERS)
      .then((res) => setBlockedUsers(res.data))
      .catch(() => {});
  }, [reload]);
  return (
    <div>
      {blockedUsers.map((user, key) => (
        <div className="flex justify-between" key={key}>
          <MyAvatar
            src={user.media.avatarUrl}
            name={user.firstName + " " + user.lastName}
            subtext={user.userName}
          />
          <Button
            color="error"
            sx={{ textTransform: "none" }}
            onClick={() => {
              axios
                .post(UNBLOCK_USER, { id: user.id })
                .then(() => {
                  toast.success("user blocked");
                  setReload(reload + 1);
                })
                .catch(() => {});
            }}
          >
            Unblock
          </Button>
        </div>
      ))}
    </div>
  );
}
