import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import React from "react";
import backImg from "../assets/img/back.svg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.primary.main,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  textGrey: {
    color: "#626262",
  },
  colorGreen: {
    backgroundColor: "#e5f8f1",
  },
}));

function Title({ back, children }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const theme = useTheme();
  return (
    <div
      className={clsx(
        classes.colorGreen,
        "shadow-black-100 flex p-3 shadow-md"
      )}
    >
      <div className="z-50 flex items-center">
        <img onClick={goBack} src={backImg} alt="Back" />
      </div>
      <div className="z-0 -ml-7 flex w-full items-center justify-center">
        <Typography variant="h6" color={theme.palette.primary.main}>
          {children}
        </Typography>
      </div>
    </div>
  );
}

export default Title;
