import { useState } from "react";
import { getLastDate, DateOptions } from "@helpers";
import { ActionFilters } from "./useActionFilters";

export default function useDateFilters({
  setDateFilters,
  prefix,
  activeFilter,
}) {
  // Props for Date Filters
  const [activeDateFilter, setActiveDateFilter] = useState("All");
  const getDateColumn = () => {
    if (activeFilter === ActionFilters.ALL) {
      return "createdAt";
    } else if (activeFilter === ActionFilters.DISABLED) {
      return "disabledAt";
    } else if (activeFilter === ActionFilters.DELETED) {
      return "deletedAt";
    }
  };

  const dateFilters = [
    {
      label: DateOptions.ANY,
      onClick: () => {
        setActiveDateFilter(DateOptions.ANY);
        setDateFilters({});
      },
    },
    {
      label: `${activeFilter === ActionFilters.ALL ? prefix : activeFilter} ${
        DateOptions.TODAY
      }`,
      onClick: () => {
        setActiveDateFilter(DateOptions.TODAY);
        setDateFilters({
          [getDateColumn()]: { ">=": getLastDate(DateOptions.TODAY) },
        });
      },
    },
    {
      label: `${activeFilter === ActionFilters.ALL ? prefix : activeFilter} ${
        DateOptions.THIS_WEEK
      }`,
      onClick: () => {
        setActiveDateFilter(DateOptions.THIS_WEEK);
        setDateFilters({
          [getDateColumn()]: { ">=": getLastDate(DateOptions.THIS_WEEK) },
        });
      },
    },
    {
      label: `${activeFilter === ActionFilters.ALL ? prefix : activeFilter} ${
        DateOptions.THIS_MONTH
      }`,
      onClick: () => {
        setActiveDateFilter(DateOptions.THIS_MONTH);
        setDateFilters({
          [getDateColumn()]: { ">=": getLastDate(DateOptions.THIS_MONTH) },
        });
      },
    },
  ];

  return {
    dateFilters,
    activeDateFilter,
  };
}
