import { Avatar } from "@mui/material";
import CommentText from "./CommentText";
import { useState } from "react";
import { calTime } from "../helpers";
import { useNavigate } from "react-router-dom";
import { REPORT_FORM } from "@constants/api";

export default function Comment({ comment, setCommentToReply }) {
  const [showReply, setShowReply] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex">
        <Avatar
          src={comment?.user?.media?.avatarUrl}
          className="z-0 mr-1 mt-3 self-start"
        />
        <div className="flex flex-col">
          <div className="flex items-center">
            <CommentText>{comment.comment}</CommentText>
            <small className=" inline ml-3">
              {calTime(comment?.createdAt)}
            </small>
          </div>
          <div>
            <span
              className="text-sm font-normal cursor-pointer mr-2 text-gray-500"
              onClick={() => setCommentToReply(comment)}
            >
              Reply
            </span>
            <span
              className="text-sm font-normal cursor-pointer text-gray-500"
              onClick={() =>
                navigate(REPORT_FORM, {
                  state: { id: comment?.id, report: "comment" },
                })
              }
            >
              Report
            </span>
          </div>
          {!showReply && comment?.replies?.length > 0 && (
            <span
              className="text-sm font-normal cursor-pointer text-gray-500"
              onClick={() => setShowReply(true)}
            >
              ____View Replies____
            </span>
          )}
        </div>
      </div>

      {showReply &&
        comment?.replies?.map((reply) => (
          <div key={reply?.id} className="ml-4 mt-2 flex">
            <Avatar
              src={comment?.user?.media?.avatarUrl}
              className="z-0 mr-1 mt-3"
            />
            <div className="flex items-center">
              <CommentText>{reply?.comment}</CommentText>
              <small className=" inline ml-3">
                {calTime(reply?.createdAt)}
              </small>
            </div>
          </div>
        ))}
    </div>
  );
}
